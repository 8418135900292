import { DropdownService } from './../../../../core/providers/dropdown.service';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BomMappingService } from './../../../../core/services/bom-mapping/bom-mapping.service';
import { MaterialService } from './../../../../core/services/material/material.service';
import { WorkTypeService } from './../../../../core/services/worktype/worktype.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ModelType } from 'src/app/core/models/modelType';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Project } from 'src/app/core/models/project';
import { WorkType } from 'src/app/core/models/workType';
import {
  CreateBOMMappingWorkType,
  CreateBOMMappingModel,
} from 'src/app/core/services/bom-mapping/model';
import { BOMMapping } from 'src/app/core/models/bom';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Material, MaterialUnit } from 'src/app/core/models/material';
import { MappingWorktypeComponent } from '../mapping-worktype/mapping-worktype.component';
import { MatTable } from '@angular/material/table';
import * as uuid from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ExcelHelperService } from 'src/app/core/providers/excel-helper.service';
import swal from 'sweetalert2';
import { MatSelectChange } from '@angular/material/select';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DropdownModel } from 'src/app/core/models/dropdown';
export class IMappedWorkTypeMaterial {
  bomMappingId?: number;
  workTypeMappingId?: number;
  uuid!: string;
  workTypeId!: number;
  workTypeCode!: string;
  workTypeName!: string;
  version?: number;
  usedFlag?: boolean;
  refNo?: number;
  materials?: IMappedMaterials[];
}
export class IMappedMaterials {
  bomWorkTypeMaterialId?: number;
  materialId!: number;
  materialCode!: string;
  materialName!: string;
  materialUnitId!: number;
  materialUnitCode!: string;
  materialUnitName!: string;
  totalBOM!: number;
}

interface IUploadWorkType {
  WorkTypeCode: string;
  MaterialCode: string;
  BOM: number;
}

@Component({
  selector: 'app-bom-mapping-dialog',
  templateUrl: './bom-mapping-dialog.component.html',
  styleUrls: ['./bom-mapping-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class BomMappingDialogComponent implements OnInit, AfterViewInit {
  @ViewChild(MatTable) table!: MatTable<IMappedWorkTypeMaterial>;
  @ViewChild('importBOM') importBOM!: ElementRef;

  form = this.fb.group({
    project: new FormControl(null, Validators.required),
    modelType: new FormControl(null, Validators.required),
    name: new FormControl('', [Validators.required, Validators.maxLength(200)]),
  });

  projectFormControl = new FormControl(null);
  filteredProjects!: Observable<Project[]>;
  version = 1;
  refNo = 0;
  displayedColumns: string[] = [
    'create',
    'workType',
    'version',
    'usedFlag',
    'actions',
  ];

  workTypes?: WorkType[];
  materials?: Material[];
  mappedWorkTypeMaterials: IMappedWorkTypeMaterial[] = [];
  expandedElement: IMappedWorkTypeMaterial | null = null;
  requestWithoutBOMs: any[] = [];
  requestWithoutBOMDisplayColumns: string[] = [
    'detailId',
    'unitCode',
    'modelCode',
    'workType',
    'material',
    'qty',
    'remark',
    'createBy',
    'createDate',
  ];
  units?: DropdownModel[] = [];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<BomMappingDialogComponent>,
    private workTypeService: WorkTypeService,
    private materialService: MaterialService,
    private bomMappingService: BomMappingService,
    private DropdownService: DropdownService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private dropdown: DropdownService,
    private excelHelper: ExcelHelperService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dropdownProject: Project[];
      dropdownModelType: ModelType[];
      dropdownWorkType: WorkType[];
      bomMapping: BOMMapping;
    }
  ) {}
  ngAfterViewInit(): void {
    if (this.data.bomMapping) {
      var project = this.data.dropdownProject.find(
        (o) => o.projectCode == this.data.bomMapping.projectCode
      );
      var modelType = this.data.dropdownModelType.find(
        (o) => o.modelId == this.data.bomMapping.modelId
      );

      if (project != null) {
        this.fetchModel(project?.projectCode!);
      }
      if (this.data.bomMapping.workTypes) {
        this.data.bomMapping.workTypes.forEach((workType) => {
          let mapWorkTypeMaterial = new IMappedWorkTypeMaterial();
          let materials = new Array<IMappedMaterials>();

          workType.bomChild?.materials.forEach((material) => {
            let mappedMaterial = new IMappedMaterials();

            mappedMaterial.bomWorkTypeMaterialId =
              material.bomWorkTypeMaterialId;
            mappedMaterial.materialId = material.materialId;
            mappedMaterial.materialCode = material.materialCode;
            mappedMaterial.materialName = material.materialName;
            mappedMaterial.materialUnitId =
              material.materialUnit?.materialUnitId!;
            mappedMaterial.materialUnitCode =
              material.materialUnit?.materialUnitCode!;
            mappedMaterial.materialUnitName =
              material.materialUnit?.materialUnitName!;
            mappedMaterial.totalBOM = material.materialBOM?.totalBOM!;

            materials.push(mappedMaterial);
          });

          mapWorkTypeMaterial.uuid = uuid.v4();
          mapWorkTypeMaterial.bomMappingId = this.data.bomMapping.bomMappingId;
          mapWorkTypeMaterial.workTypeMappingId = workType.workTypeMappingId;
          mapWorkTypeMaterial.workTypeId = workType.workTypeId;
          mapWorkTypeMaterial.workTypeCode = workType.workTypeCode;
          mapWorkTypeMaterial.workTypeName = workType.workTypeName;
          mapWorkTypeMaterial.version = workType.version;
          mapWorkTypeMaterial.usedFlag = workType.usedFlag;
          mapWorkTypeMaterial.materials = materials;

          this.mappedWorkTypeMaterials.push(mapWorkTypeMaterial);
        });

        this.table.renderRows();
      }

      this.form.controls['project'].setValue(project);
      this.projectFormControl.setValue(
        project?.projectCode + ' - ' + project?.projectName
      );
      this.form.controls['project'].markAsUntouched();
      this.form.controls['modelType'].setValue(modelType);
      this.form.controls['name'].setValue(this.data.bomMapping.name);
      this.form.updateValueAndValidity();

      this.cdr.detectChanges();

      if (this.data.bomMapping.bomMappingId) {
        this.DropdownService.getDropdownUnit(
          this.data.bomMapping.projectCode,
          this.data.bomMapping.modelCode
        ).then((response) => {
          this.units = response;
        });

        this.fetchRequestWithoutBOM(
          this.data.bomMapping.projectCode,
          this.data.bomMapping.modelCode
        );
      }
    }
  }

  compareProject(input: Project, bind: Project) {
    return input && bind && input.projectCode === bind.projectCode;
  }

  compareModelType(input: ModelType, bind: ModelType) {
    return input && bind && input.modelId === bind.modelId;
  }

  async ngOnInit() {
    this.workTypes = await this.data.dropdownWorkType;
    this.data.dropdownModelType = [];
    this.materials = await this.DropdownService.getDropdownMaterial();
    this.filteredProjects = this.projectFormControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value))
    );
    // this.projectFormControl.setValue(null);
  }

  private _filter(value: string): Project[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.data.dropdownProject.filter((project) =>
        (project.projectCode + '-' + project.projectName)
          .toLowerCase()
          .includes(filterValue)
      );
    } else {
      return this.data.dropdownProject.slice();
    }
  }

  projectSelected(event: MatAutocompleteSelectedEvent): void {
    var value = event.option.value as Project;
    var viewValue = event.option.viewValue;

    this.projectFormControl.setValue(viewValue);

    this.form.controls['project'].setValue(value);

    this.fetchModel(value.projectCode);

    this.form.updateValueAndValidity();
  }

  selectModelType(event: MatSelectChange): void {
    let value = event.value;
    let modelCode = value.modelCode;
  }

  fetchRequestWithoutBOM(projectCode: string, modelCode: string) {
    this.bomMappingService
      .getRequestMaterialWithoutBOM(projectCode)
      .then((resp) => {
        resp.forEach((data: any) => {
          if (data.isZone === false) {
            data.requests.forEach((request: any) => {
              request.materials.forEach((material: any) => {
                let tempModelCode: string = (data.modelCode ?? '').trim();
                let tempUnitCode = this.units?.find(
                  (o) => o.value === data.unitCode
                );
                let isMatchData =
                  tempModelCode === modelCode &&
                  tempUnitCode &&
                  tempUnitCode !== null;
                let isDisabled = !isMatchData;

                this.requestWithoutBOMs.push({
                  requestId: data.requestId,
                  storeId: data.storeId,
                  projectCode: data.projectCode,
                  transactionCode: data.transactionCode,
                  unitCode: data.unitCode,
                  modelCode: data.modelCode,
                  remark: data.remark,
                  workTypeId: request.workTypeId,
                  workTypeCode: request.workTypeCode,
                  workTypeName: request.workTypeName,
                  workType: `[${request.workTypeCode}] ${request.workTypeName}`,
                  detailId: material.detailId,
                  materialId: material.materialId,
                  materialCode: material.materialCode,
                  materialName: material.materialName,
                  material: `[${material.materialCode}] ${material.materialName}`,
                  qty: material.materialRequest?.totalRequest ?? 0,
                  createDate: material.createDate,
                  createBy: material.createBy,
                  selected: isMatchData,
                  disabled: isDisabled,
                });
              });
            });
          }
        });

        this.requestWithoutBOMs = [
          ...this.requestWithoutBOMs.sort((a, b) => {
            if (a.selected === true && b.selected === true) {
              return a.detailId > b.detailId ? 1 : -1;
            } else if (a.selected === false && b.selected === true) {
              return 1;
            } else {
              return -1;
            }
          }),
        ];
      });
  }

  async fetchModel(projectCode: string) {
    this.data.dropdownModelType = await this.dropdown.getDropdownModelTypeList(
      projectCode
    );
  }

  async onSubmit() {
    if (!this.form.valid) return;

    var mappingWorkTypes = Array<CreateBOMMappingWorkType>();

    if (
      this.mappedWorkTypeMaterials != null &&
      this.mappedWorkTypeMaterials.length > 0
    ) {
      this.mappedWorkTypeMaterials.forEach((ele) => {
        if (ele.materials != null && ele.materials.length > 0) {
          ele.materials.forEach((mappedItem) => {
            mappingWorkTypes.push({
              bomMappingId: ele.bomMappingId ?? 0,
              bomWorkTypeMaterialId: mappedItem.bomWorkTypeMaterialId ?? 0,
              workTypeMappingId: ele.workTypeMappingId ?? 0,
              workTypeId: ele.workTypeId,
              materialId: mappedItem.materialId,
              qty: mappedItem.totalBOM,
              refNo: ele.refNo ?? 0,
              version: ele.version ?? 0,
              active: true,
            });
          });
        }
      });
    }

    var request = new CreateBOMMappingModel({
      bomMappingId:
        this.data.bomMapping && this.data.bomMapping.bomMappingId > 0
          ? this.data.bomMapping.bomMappingId
          : 0,
      projectCode: (this.form.controls['project'].value as Project).projectCode,
      modelId: (this.form.controls['modelType'].value as ModelType).modelId,
      name: this.form.controls['name'].value,
      version: this.version,
      refNo: this.refNo,
      active: true,
      workTypes: mappingWorkTypes,
    });

    var response: boolean = false;

    if (request.bomMappingId && request.bomMappingId > 0) {
      response = (await this.bomMappingService.updateBOMMaping(
        request.bomMappingId,
        request
      )) as boolean;
    } else {
      response = (await this.bomMappingService.createBOMMapping(
        request
      )) as boolean;
    }

    if (response == true) {
      this.snackBar.open('บันทึกข้อมูลสำเร็จ', 'ตกลง', { duration: 3000 });
      this.dialogRef.close();
    }
  }

  onSelectWorkType() {}

  validateMappedWorkTypeMaterial() {
    let status = false;

    if (
      this.mappedWorkTypeMaterials != null &&
      this.mappedWorkTypeMaterials.length > 0
    ) {
      this.mappedWorkTypeMaterials.forEach((workType) => {
        if (workType.materials != null && workType.materials.length > 0) {
          status = true;
        }
      });
    }

    return status;
  }

  onDeleteWorkType(item: IMappedWorkTypeMaterial) {
    var index = this.mappedWorkTypeMaterials.findIndex(
      (ele) => ele.uuid === item.uuid
    );
    this.mappedWorkTypeMaterials.splice(index, 1);
    this.table.renderRows();
  }

  onEditWorkType(item: IMappedWorkTypeMaterial) {
    this.onCreateWorkType(item);
  }

  onCopy(item: IMappedWorkTypeMaterial) {
    if (!item.usedFlag || item.workTypeMappingId == 0) return;

    var temp = JSON.parse(JSON.stringify(item)) as IMappedWorkTypeMaterial;

    temp.refNo = item.workTypeMappingId;
    temp.workTypeMappingId = 0;
    temp.usedFlag = false;
    temp.version = (item.version ?? 0) + 1;
    temp.uuid = uuid.v4();

    temp.materials = temp.materials?.map((ele) => {
      ele.bomWorkTypeMaterialId = 0;
      return ele;
    });

    this.mappedWorkTypeMaterials.push(temp);
    this.mappedWorkTypeMaterials = [...this.mappedWorkTypeMaterials];
  }

  workTypeIsUsed(workTypeCode: string): {
    isUsed: boolean;
    version: number;
    refNo: number;
  } {
    const _curWorkType =
      this.data.bomMapping?.workTypes.filter(
        (workType) => workType.workTypeCode === workTypeCode
      ) ?? [];

    if (_curWorkType.length === 0)
      return { isUsed: false, version: 1, refNo: 0 };

    const curWorkType = _curWorkType.reduce((prev, cur) =>
      prev.version! > cur.version! ? prev : cur
    );

    return {
      isUsed: curWorkType.usedFlag ?? false,
      version: curWorkType.usedFlag
        ? curWorkType.version! + 1
        : curWorkType.version
        ? curWorkType.version
        : 1,
      refNo:
        curWorkType.refNo === 0
          ? curWorkType.workTypeMappingId!
          : curWorkType.refNo!,
    };
  }

  async onImportItem(evt: any) {
    await this.excelHelper.uploadExcel(evt, (data: any) => {
      var list = data as Array<IUploadWorkType>;

      let workTypes = new Array<IMappedWorkTypeMaterial>();
      let isNotFoundWorkType = false;
      let isNotFoundMaterial = false;
      let notFoundWorkTypeCode = '';
      let notFoundMaterialCode = '';

      list.forEach((item) => {
        if (!workTypes.find((o) => o.workTypeCode == item.WorkTypeCode)) {
          let workType = new IMappedWorkTypeMaterial();

          var mapWorkType = this.workTypes?.find((w) => {
            return w.workTypeCode == item.WorkTypeCode;
          });

          if (!mapWorkType) {
            isNotFoundWorkType = true;
            notFoundWorkTypeCode = item.WorkTypeCode;
            return;
          }

          let materials = new Array<IMappedMaterials>();

          var workTypeMaterials = list.filter((material) => {
            return material.WorkTypeCode == item.WorkTypeCode;
          });

          workTypeMaterials.forEach((material) => {
            let materialItem = new IMappedMaterials();

            var mapMaterial = this.materials?.find((m) => {
              return m.materialCode == material.MaterialCode;
            });

            if (!mapMaterial) {
              isNotFoundMaterial = true;
              notFoundMaterialCode = material.MaterialCode;
              return;
            }

            var checkDupplicate = materials.find(
              (o) => o.materialCode == mapMaterial?.materialCode
            );

            if (mapMaterial && !checkDupplicate) {
              materialItem.bomWorkTypeMaterialId = 0;
              materialItem.materialId = mapMaterial?.materialId;
              materialItem.materialCode = mapMaterial?.materialCode;
              materialItem.materialName = mapMaterial?.materialName;
              materialItem.materialUnitId =
                mapMaterial?.materialUnit!.materialUnitId;
              materialItem.materialUnitCode =
                mapMaterial?.materialUnit!.materialUnitCode;
              materialItem.materialUnitName =
                mapMaterial?.materialUnit!.materialUnitName;
              materialItem.totalBOM =
                typeof material.BOM === 'number' ? material.BOM : 0;

              materials.push(materialItem);
            }
          });

          const check = this.workTypeIsUsed(mapWorkType.workTypeCode);

          workType.workTypeId = mapWorkType.workTypeId;
          workType.workTypeCode = mapWorkType.workTypeCode;
          workType.workTypeName = mapWorkType.workTypeName;
          workType.uuid = uuid.v4();
          workType.bomMappingId = this.data.bomMapping?.bomMappingId ?? 0;
          workType.refNo = check.refNo;
          workType.usedFlag = false;
          workType.version = check.version;
          workType.materials = materials;
          workTypes.push(workType);
        }
      });

      if (isNotFoundWorkType) {
        swal.fire(
          'Import ข้อมูลไม่สำเร็จ',
          `ไม่พบ WorkType: ${notFoundWorkTypeCode} ที่ระบุ</br>โปรดเพิ่มข้อมูลในหน้าจัดการหมวดงานก่อน`,
          'error'
        );
        return;
      }

      if (isNotFoundMaterial) {
        swal.fire(
          'Import ข้อมูลไม่สำเร็จ',
          `ไม่พบวัสดุ: ${notFoundMaterialCode} ที่ระบุ</br>โปรดเพิ่มข้อมูลในหน้าจัดการวัสดุก่อน`,
          'error'
        );
        return;
      }

      this.mappedWorkTypeMaterials = [...workTypes];
      this.table.renderRows();
    });

    this.importBOM.nativeElement.value = '';
  }

  async onLoadTemplate() {
    // let header = [{
    //   '1':
    // }];
    // await this.excelHelper.downloadExcel(data, header, 'bom-mapping-template');
  }

  async onCreateWorkType(edit?: IMappedWorkTypeMaterial) {
    const dialogRef = this.dialog.open(MappingWorktypeComponent, {
      width: '850px',
      disableClose: true,
      data: {
        workTypes: this.workTypes,
        materials: this.materials,
        editValue: edit,
        mappedWorkTypes: this.data.bomMapping?.workTypes ?? null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result instanceof IMappedWorkTypeMaterial) {
        if (result.uuid) {
          var index = this.mappedWorkTypeMaterials.findIndex(
            (o) => o.uuid == result.uuid
          );
          if (index != -1) {
            this.mappedWorkTypeMaterials.splice(index, 1, result);
            this.table.renderRows();
            return;
          }
        }
        this.mappedWorkTypeMaterials.push(result as IMappedWorkTypeMaterial);
        this.table.renderRows();
      }
    });
  }

  onCheckMergeBOM(event: MatCheckboxChange, detailId: number) {
    const curIndex = this.requestWithoutBOMs.findIndex(
      (request) => request.detailId === detailId
    );

    this.requestWithoutBOMs[curIndex].selected = event.checked;
  }

  newUnitToRequest(event: any, element: any) {
    const curIndex = this.requestWithoutBOMs.findIndex(
      (request) => request.detailId === element.detailId
    );
    this.requestWithoutBOMs[curIndex].selected = true;
    this.requestWithoutBOMs[curIndex].disabled = false;
    this.requestWithoutBOMs[curIndex].unitCode = event.value.value;
    this.requestWithoutBOMs[curIndex].modelCode =
      this.data.bomMapping.modelCode;
  }

  async mergeBOM() {
    const materialWithoutBOMList: any = [];
    this.requestWithoutBOMs.forEach((request) => {
      if (request.selected === true) {
        materialWithoutBOMList.push({
          requestId: request.requestId,
          detailId: request.detailId,
          unitCode: request.unitCode,
          workTypeID: request.workTypeId,
          materialID: request.materialId,
          bomId: this.data.bomMapping.bomMappingId,
        });
      }
    });

    if (materialWithoutBOMList.length > 0) {
      const payload = {
        bomId: this.data.bomMapping.bomMappingId,
        storeId: this.requestWithoutBOMs[0].storeId,
        projectCode: this.requestWithoutBOMs[0].projectCode,
        modelCode: this.data.bomMapping.modelCode,
        materialWithoutBOMList: materialWithoutBOMList,
      };

      console.log('request', JSON.stringify(payload));

      this.bomMappingService
        .mergeRequestWithoutBOM(payload)
        .then((response) => {
          if (response == true) {
            this.snackBar.open('บันทึกข้อมูลสำเร็จ', 'ตกลง', {
              duration: 3000,
            });
            this.dialogRef.close();
          }
        });
    }
  }
}
