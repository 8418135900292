<div class="bg">
    <div class="full-container">
        <div class="overlay-color">
            <div class="text-logo-container">
                <h1>PCF Site werehouse</h1>
            </div>
            <p style="color: red;">{{authErrorMessage}}</p>
            <button mat-raised-button *ngIf="!isAuthenticated" (click)="login()">เข้าสู่ระบบด้วย Sansiri Account</button>
            <!-- <button mat-raised-button *ngIf="isAuthenticated" (click)="logout()">ออกจากระบบ</button> -->
        </div>
    </div>
</div>