<h4 mat-dialog-title>สร้างสูตร BOM</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div class="form-container">
      <mat-form-field appearance="standard">
        <mat-label>โครงการ</mat-label>
        <input
          type="text"
          placeholder="เลือกโครงการ"
          matInput
          [formControl]="projectFormControl"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="projectSelected($event)"
        >
          <mat-option
            *ngFor="let project of filteredProjects | async"
            [value]="project"
          >
            {{ project.projectCode + " - " + project.projectName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.controls['project'].errors?.required"
          >กรุณาระบุโครงการ</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>Model Type</mat-label>
        <mat-select
          id="modelType"
          formControlName="modelType"
          [compareWith]="compareModelType"
          (selectionChange)="selectModelType($event)"
          required
        >
          <mat-option
            *ngFor="let modelType of data.dropdownModelType"
            [value]="modelType"
            [disabled]="data.dropdownModelType.length === 0"
          >
            {{ modelType.modelCode }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['modelType'].errors?.required"
          >กรุณาระบุ Model Type</mat-error
        >
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>ชื่อสูตร BOM (ไม่เกิน 200 ตัวอักษร)</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          placeholder="ตย. สูตร BOM สำหรับงานเพดาน"
          required
        />
        <mat-error *ngIf="form.controls['name'].errors?.required"
          >กรุณาตั้งชื่อ</mat-error
        >
        <mat-error *ngIf="form.controls['name'].errors?.maxlength != null"
          >ระบุได้ไม่เกิน
          {{ form.controls["name"].errors?.maxlength.requiredLength }}
          ตัวอักษร</mat-error
        >
      </mat-form-field>

      <div class="layout-full-left" style="margin-bottom: 5px; margin-top: 5px">
        <input
          #importBOM
          type="file"
          (change)="onImportItem($event)"
          multiple="false"
        />
        <a
          target="_self"
          href="../../../assets/downloads/Template_BOM.xlsx"
          download="Template_BOM.xlsx"
          ><span>Download Template</span></a
        >
      </div>

      <table
        mat-table
        [dataSource]="mappedWorkTypeMaterials"
        class="mat-elevation-z8"
        multiTemplateDataRows
      >
        <ng-container matColumnDef="create">
          <th mat-header-cell *matHeaderCellDef>
            <button
              mat-icon-button
              color="primary"
              type="button"
              (click)="onCreateWorkType()"
            >
              <mat-icon>add</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element"></td>
        </ng-container>

        <ng-container matColumnDef="workType">
          <th mat-header-cell *matHeaderCellDef>หมวดงาน</th>
          <td mat-cell *matCellDef="let element">
            [{{ element.workTypeCode }}] {{ element.workTypeName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef>เวอร์ชั่น</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            {{ element.version > 0 ? "version " + element.version : "" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="usedFlag">
          <th mat-header-cell *matHeaderCellDef>สถานะการใช้งาน</th>
          <td mat-cell *matCellDef="let element" style="text-align: center">
            <span *ngIf="element.usedFlag"> ถูกใช้งานแล้ว</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>จัดการ</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-icon-button
              color="primary"
              type="button"
              (click)="onCopy(element)"
              *ngIf="element.usedFlag"
            >
              <mat-icon>content_copy</mat-icon>
            </button>
            <button
              mat-icon-button
              color="warn"
              type="button"
              (click)="onDeleteWorkType(element)"
              [disabled]="element.usedFlag"
            >
              <mat-icon>delete</mat-icon>
            </button>
            <button
              mat-icon-button
              color="primary"
              type="button"
              (click)="onEditWorkType(element)"
              [disabled]="element.usedFlag"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="displayedColumns.length"
          >
            <div class="element-detail">
              <table
                mat-table
                [dataSource]="element.materials"
                class="mat-elevation-z8"
                *ngIf="
                  element.materials != null && element.materials.length > 0
                "
              >
                <ng-container matColumnDef="material">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="text-align: left; width: 60%"
                  >
                    วัสดุ
                  </th>
                  <td mat-cell *matCellDef="let item">
                    [{{ item.materialCode }}] {{ item.materialName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="materialUnit">
                  <th
                    mat-header-cell
                    *matHeaderCellDef
                    style="text-align: left; width: 100px"
                  >
                    หน่วย
                  </th>
                  <td mat-cell *matCellDef="let item">
                    {{ item.materialUnitName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="materialTotalBOM">
                  <th mat-header-cell *matHeaderCellDef>กำหนด BOM</th>
                  <td mat-cell *matCellDef="let item" class="numberFormat">
                    {{ item.totalBOM | number : "1.2-2" }}
                  </td>
                </ng-container>

                <tr
                  mat-header-row
                  *matHeaderRowDef="[
                    'material',
                    'materialUnit',
                    'materialTotalBOM'
                  ]"
                ></tr>
                <tr
                  mat-row
                  *matRowDef="
                    let row;
                    columns: ['material', 'materialUnit', 'materialTotalBOM']
                  "
                ></tr>
              </table>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: displayedColumns"
          class="element-row"
          [class.expanded-row]="expandedElement === element"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="detail-row"
        ></tr>
      </table>

      <div
        class="layout-full-left"
        style="margin-top: 2rem"
        *ngIf="requestWithoutBOMs && requestWithoutBOMs.length > 0"
      >
        <a mat-flat-button color="primary" (click)="mergeBOM()">
          Auto ปรับยอด BOM
        </a>

        <table
          mat-table
          [dataSource]="requestWithoutBOMs"
          class="mat-elevation-z8"
        >
          <ng-container matColumnDef="detailId">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                [checked]="element.selected"
                [disabled]="element.disabled"
                [color]="'primary'"
                (change)="onCheckMergeBOM($event, element.detailId)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="unitCode">
            <th mat-header-cell *matHeaderCellDef>แปลง/Block</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="!element.disabled">{{ element.unitCode }}</span>
              <div *ngIf="element.disabled">
                <mat-select
                  (selectionChange)="newUnitToRequest($event, element)"
                  required
                >
                  <mat-option
                    *ngFor="let unit of units"
                    [value]="unit"
                    [disabled]="units?.length === 0"
                  >
                    {{ unit.value }}
                  </mat-option>
                </mat-select>
                <mat-error>ไม่พบแปลง {{ element.unitCode }}</mat-error>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="modelCode">
            <th mat-header-cell *matHeaderCellDef>โมเดล</th>
            <td mat-cell *matCellDef="let element">
              {{ element.modelCode }}
            </td>
          </ng-container>

          <ng-container matColumnDef="workType">
            <th mat-header-cell *matHeaderCellDef>หมวดงาน</th>
            <td mat-cell *matCellDef="let element">
              {{ element.workType }}
            </td>
          </ng-container>

          <ng-container matColumnDef="material">
            <th mat-header-cell *matHeaderCellDef>วัสดุ</th>
            <td mat-cell *matCellDef="let element">
              {{ element.material }}
            </td>
          </ng-container>

          <ng-container matColumnDef="qty">
            <th mat-header-cell *matHeaderCellDef>จำนวนเบิก</th>
            <td mat-cell *matCellDef="let element">
              {{ element.qty | number : "1.2-2" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="remark">
            <th mat-header-cell *matHeaderCellDef>หมายเหตุ</th>
            <td mat-cell *matCellDef="let element">
              {{ element.remark }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createDate">
            <th mat-header-cell *matHeaderCellDef>วันที่สร้าง</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createDate | date : "dd/MM/yyyy HH:mm" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createBy">
            <th mat-header-cell *matHeaderCellDef>สร้างโดย</th>
            <td mat-cell *matCellDef="let element">
              {{ element.createBy }}
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="requestWithoutBOMDisplayColumns"
          ></tr>
          <tr
            mat-row
            *matRowDef="let element; columns: requestWithoutBOMDisplayColumns"
            class="element-row"
          ></tr>
        </table>
      </div>
      <!-- end layout -->
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>ยกเลิก</button>
    <button
      mat-button
      color="primary"
      type="submit"
      [disabled]="!form.valid || !validateMappedWorkTypeMaterial()"
    >
      บันทึก
    </button>
  </div>
</form>
