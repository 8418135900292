export class FilterOptions {
  isExpand = true;
  search: FilterSettings = { visible: true, required: false };
  dateRangePicker: FilterSettings = { visible: true, required: false };
  orderBy: FilterSettings = { visible: false, required: false };
  sortBy: FilterSettings = { visible: false, required: false };
  dropdownProject: FilterSettings = { visible: false, required: false };
  dropdownStore: FilterSettings = { visible: false, required: false };
  dropdownTransportStatus: FilterSettings = { visible: false, required: false };
  dropdownWorkType: FilterSettings = { visible: false, required: false };
  dropdownModelType: FilterSettings = { visible: false, required: false };
  dropdownMaterial: FilterSettings = { visible: false, required: false };
  documentCodeInput: FilterSettings = { visible: false, required: false };
  unitCodeInput: FilterSettings = { visible: false, required: false };
  checkBoxOverBOMFlag: FilterSettings = { visible: false, required: false };
  materialDocumentInput: FilterSettings = { visible: false, required: false };
  showZoneOnly: FilterSettings = { visible: false, required: false };
}
export interface FilterSettings {
  visible?: boolean;
  required?: boolean;
}
