import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Project } from '../../models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  constructor(private api: ApiService) { }

  async getList() {
    return await this.api.get('/project') as Array<Project>;
  }
}
