<mat-card>
  <h4><b>จัดการ BOM</b></h4>

  <!-- filter -->
  <app-filters
    (fetchTable)="fetchTable($event)"
    [options]="filterOptions"
  ></app-filters>
  <!-- ----- end filter ----- -->
  <button mat-flat-button color="primary" (click)="onCreate()">สร้าง</button>
  <span class="tiny-space"></span>

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="width: 100%"
    >
      <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef style="width: 40px">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" class="rowNumber">
          {{ (queryParam.start - 1) * queryParam.limit + (i + 1) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">ชื่อ</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="projectName">
        <th mat-header-cell *matHeaderCellDef>โครงการ</th>
        <td mat-cell *matCellDef="let element">
          {{ element.projectCode }} - {{ element.projectName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="modelCode">
        <th mat-header-cell *matHeaderCellDef style="width: 10%">Model Type</th>
        <td mat-cell *matCellDef="let element">{{ element.modelCode }}</td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef style="width: 40px">เวอร์ชั่น</th>
        <td mat-cell *matCellDef="let element" class="numberFormat">
          {{ element.version }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updateDate">
        <th mat-header-cell *matHeaderCellDef style="width: 40px">
          แก้ไขล่าสุด
        </th>
        <td mat-cell *matCellDef="let element">{{ element.updateDate }}</td>
      </ng-container>

      <ng-container matColumnDef="updateBy">
        <th mat-header-cell *matHeaderCellDef style="width: 15%">แก้ไขโดย</th>
        <td mat-cell *matCellDef="let element">{{ element.updateBy }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 100px">จัดการ</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            type="button"
            (click)="onCreate(element)"
            [disabled]="!element.active"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <mat-slide-toggle
            color="primary"
            [checked]="element.active"
            (click)="onUpdateStatus(element)"
          >
          </mat-slide-toggle>

          <!-- <button *ngIf="element.active"
            mat-icon-button
            color="warn"
            type="button"
            (click)="onDelete(element)"
            matTooltip="กดเพื่อปิดการใช้งาน"
          >
            <mat-icon>close</mat-icon>
          </button>

          <button *ngIf="!element.active"
            mat-icon-button
            color="primary"
            type="button"
            (click)="onActive(element)"
            matTooltip="กดเพื่อเปิดการใช้งาน"
          >
            <mat-icon>check</mat-icon>
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="totalRows"
      [pageSize]="queryParam.limit"
      [pageSizeOptions]="[10, 20, 50, 100]"
      (page)="
        queryParam.start = $event.pageIndex + 1;
        queryParam.limit = $event.pageSize;
        fetchTable(queryParam)
      "
      showFirstLastButtons
      #paginator
    >
    </mat-paginator>
  </div>
</mat-card>
