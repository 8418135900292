import { toQueryParam } from 'src/app/core/models/api-request';
import { Role } from './../models/role';
import { ApiService } from './../services/api.service';
import { WorkTypeService } from './../services/worktype/worktype.service';
import { ProjectService } from './../services/project/project.service';
import { ManageMaterialUnitService } from './../services/manage-material-unit/manage-material-unit.service';
import { Injectable } from '@angular/core';
import { Project } from '../models/project';
import { WorkType } from '../models/workType';
import { ModelService } from '../services/model/model.service';
import { ModelType } from '../models/modelType';
import { User } from '../models/user';
import { Function } from '../models/function';
import { Material, MaterialUnit } from '../models/material';
import { Store } from '../models/store';
import { TransportStatus } from '../services/transport/model';
import { DataList } from './model';
import { ApiRequest } from '../models/api-request';
import { TransactionType } from '../models/TransactionType';
import { MasterStatusUnit } from '../models/unit';
import { DropdownModel } from '../models/dropdown';

@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(
    private projectService: ProjectService,
    private workTypeService: WorkTypeService,
    private modelTypeService: ModelService,
    private api: ApiService
  ) {}

  async getDropdownProjectList() {
    var projectDropdownList = Array<Project>();

    var projects = (await this.api.get('/dropdown/Project')) as Array<Project>;

    if (projects && projects.length > 0) {
      projectDropdownList = projects;
    }

    return projectDropdownList;
  }

  async getDropdownWorkTypeList() {
    var workTypeDropdownList = Array<WorkType>();

    var workTypes = (await this.api.get(
      '/dropdown/WorkType'
    )) as Array<WorkType>;

    if (workTypes && workTypes.length > 0) {
      workTypeDropdownList = workTypes;
    }

    return workTypeDropdownList;
  }

  async getDropdownModelTypeList(projectCode: string | null = '') {
    var modelTypeDropdownList = Array<ModelType>();

    var models = (await this.api.get(
      '/dropdown/Model',
      '',
      toQueryParam(
        new ApiRequest({
          search: '',
          start: 0,
          limit: 0,
          sortBy: '',
          orderBy: '',
          startDate: '',
          endDate: '',
          filters: [
            new Map<string, string>().set('projectCode', projectCode ?? ''),
          ],
        })
      )
    )) as Array<ModelType>;

    if (models && models.length > 0) {
      modelTypeDropdownList = models;
    }

    return modelTypeDropdownList;
  }
  async getDropdownRoleList() {
    var list: Role[] = [];

    var roles = (await this.api.get('/dropdown/Role')) as Array<Role>;

    if (roles) {
      list = roles;
    }

    return list;
  }
  async getDropdownFunctionList() {
    var list: Function[] = [];

    var functions = (await this.api.get(
      '/dropdown/Function'
    )) as Array<Function>;

    if (functions) {
      list = functions;
    }

    return list;
  }
  async getDropdownMaterial() {
    var list: Material[] = [];

    var materials = (await this.api.get(
      '/dropdown/Material'
    )) as Array<Material>;

    if (materials) {
      list = materials;
    }
    return list;
  }
  async getDropdownMaterialUnit() {
    var list: MaterialUnit[] = [];

    var materials = (await this.api.get(
      '/dropdown/MaterialUnit'
    )) as Array<MaterialUnit>;

    if (materials) {
      list = materials;
    }
    return list;
  }

  async getDropdownStore() {
    var list: Store[] = [];

    var stores = (await this.api.get('/dropdown/Store')) as Array<Store>;

    if (stores) {
      list = stores;
    }
    return list;
  }

  async getDropdownTransactionType() {
    var list: TransactionType[] = [];

    var TransactionType = (await this.api.get(
      '/dropdown/TransactionType'
    )) as Array<TransactionType>;

    if (TransactionType) {
      list = TransactionType;
    }
    return list;
  }

  async getDropdownTransportStatus() {
    var list: TransportStatus[] = [];

    var dataList = (await this.api.get(
      '/dropdown/DataList/TransportStatus'
    )) as Array<DataList>;

    if (dataList) {
      dataList.forEach((item) => {
        list.push(
          new TransportStatus({
            transportStatusId: item.id,
            transportStatusName: item.value,
          })
        );
      });
    }

    return list;
  }

  async getMasterStatusUnit() {
    var MasterStatusUnitDropdownList = Array<MasterStatusUnit>();

    var projects = (await this.api.get(
      '/dropdown/GetMasterStatusUnit'
    )) as Array<MasterStatusUnit>;

    if (projects && projects.length > 0) {
      MasterStatusUnitDropdownList = projects;
    }

    return MasterStatusUnitDropdownList;
  }

  async getDropdownUnit(projectCode: string, modelCode: string) {
    let units = (await this.api.get(
      `/dropdown/DropdownUnit?projectCode=${projectCode}&modelCode=${modelCode}`
    )) as Array<DropdownModel>;

    return units;
  }
}
