import { BOMMappingMaterial, Material } from './material';
export class WorkType {
  workTypeId: number;
  workTypeCode: string;
  workTypeName: string;
  workTypeMappingId?: number;
  version?: number;
  usedFlag?: boolean;
  refNo?: number;
  bomChild?: WorkTypeBOMChild;

  constructor(o: WorkType) {
    this.workTypeId = o.workTypeId;
    this.workTypeCode = o.workTypeCode;
    this.workTypeName = o.workTypeName;
    this.workTypeMappingId = o.workTypeMappingId;
    this.version = o.version;
    this.usedFlag = o.usedFlag;
    this.bomChild = o.bomChild;
  }
}

export class WorkTypeBOMChild {
  bomChildId: number;
  materials: BOMMappingMaterial[];

  /**
   *
   */
  constructor(o: WorkTypeBOMChild) {
    this.bomChildId = o.bomChildId;
    this.materials = o.materials;
  }
}
