import { Component, OnInit } from '@angular/core';
import { Profile } from './../../core/models/profile';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  isSidenavOpen = true;
  isStoreCenter = false;
  isAdminStoreCenter = false;
  isITCallcenter = false;
  isInstaller = false;
  isAdminInstaller = false;
  isEngineer = false;
  isAdminEngineer = false;
  isAdministrator = false;


  constructor(
  ) { }

  ngOnInit(): void {
    var profile = (JSON.parse(localStorage.getItem('profile')!) as Profile);
      if (profile) {
        if(profile.role.roleCode == 'PSW2100'){
          this.isStoreCenter = true;
        }else if(profile.role.roleCode == 'PSW2000'){
          this.isAdminStoreCenter = true;
        }
        else if(profile.role.roleCode == 'PSW9000'){
          this.isITCallcenter = true;
        }
        else if(profile.role.roleCode == 'PSW3100'){
          this.isInstaller = true;
        }
        else if(profile.role.roleCode == 'PSW3000'){
          this.isAdminInstaller = true;
        }
        else if(profile.role.roleCode == 'PSW4100'){
          this.isEngineer = true;
        }
        else if(profile.role.roleCode == 'PSW4000'){
          this.isAdminEngineer = true;
        }
        else if(profile.role.roleCode == 'PSW1000'){
          this.isAdministrator = true;
        }
      }
  }

  onToggleDrawer() {
    this.isSidenavOpen = !this.isSidenavOpen;
  }
}
