import { ManageMaterialService } from '../../../../core/services/manage-material/manage-material.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Project } from 'src/app/core/models/project';
import { BOMMapping } from 'src/app/core/models/bom';
import { Materiallist, MaterialModel } from 'src/app/core/models/material';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Material, MaterialUnit } from 'src/app/core/models/material';
import { DropdownMaterialUnit } from 'src/app/core/models/material-unit';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-material-dialog',
  templateUrl: './manage-material-dialog.component.html',
  styleUrls: ['./manage-material-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class ManageMaterialDialogComponent implements OnInit, AfterViewInit {
  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  form = this.fb.group({
    materialCode: new FormControl(null, [
      Validators.required,
      Validators.maxLength(30),
    ]),
    materialName: new FormControl(null, [
      Validators.required,
      Validators.maxLength(200),
    ]),
    materialWeight: new FormControl(null, [
      Validators.required,
      Validators.pattern(this.numRegex),
      Validators.minLength(0),
    ]),
    workTypes: new FormControl(null, [Validators.required]),
  });
  version = 1;
  refNo = 0;
  workTypes?: DropdownMaterialUnit[];
  materials?: Material[];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ManageMaterialDialogComponent>,
    private ManageMaterialService: ManageMaterialService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dropdownProject: Project[];
      dropdownWorkType: DropdownMaterialUnit[];
      bomMapping: BOMMapping;
      editValue: Materiallist;
    }
  ) {}
  ngAfterViewInit(): void {
    if (this.data.editValue) {
      var modelType = this.data.dropdownWorkType.find(
        (o) =>
          o.materialUnitId == this.data.editValue.materialUnit.materialUnitId
      );
      this.form.controls['materialCode'].setValue(
        this.data.editValue.materialCode
      );
      this.form.controls['materialName'].setValue(
        this.data.editValue.materialName
      );
      this.form.controls['materialWeight'].setValue(
        this.data.editValue.materialWeight
      );
      this.form.controls['workTypes'].setValue(modelType);
      this.cdr.detectChanges();
    }
  }

  async ngOnInit() {
    this.workTypes = await this.data.dropdownWorkType;
  }

  async onSubmit() {
    if (this.form.invalid) return;

    let materialUnit = new MaterialModel({
      materialId: this.data.editValue?.materialId ?? 0,
      materialCode: this.form.controls['materialCode'].value,
      materialName: this.form.controls['materialName'].value,
      materialWeight: this.form.controls['materialWeight'].value,
      materialUnitId: (this.form.controls['workTypes'].value as MaterialUnit)
        .materialUnitId,
      materialUnitCode: '',
      materialUnitName: '',
      active: true,
      totalRows: 0,
    });

    var response: boolean = false;

    if (materialUnit.materialId != 0) {
      response = (await this.ManageMaterialService.updateMaterial(
        materialUnit.materialId,
        materialUnit
      )) as boolean;
    } else {
      response = (await this.ManageMaterialService.createMaterial(
        materialUnit
      )) as boolean;
    }

    if (response == true) {
      this.snackBar.open('บันทึกข้อมูลสำเร็จ', 'ตกลง', { duration: 3000 });
      this.dialogRef.close();
    }
  }
}
