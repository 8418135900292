import { Material } from '../../models/material';
import { Store } from '../../models/store';

export class Transport {
  transportId: number;
  store: Store;
  driverName: string;
  licensePlate: string;
  transportTargetDate: string;
  materialDocument: string;
  transportStatus: TransportStatus;
  transportCreateBy?: string;
  createDateTime?: string;
  receiveBy?: string;
  receiveDateTime?: string;
  documentNo?: string;
  documentDate?: string;
  materials: TransportMaterial[];

  constructor(o: Transport) {
    this.transportId = o.transportId;
    this.store = o.store;
    this.transportCreateBy = o.transportCreateBy;
    this.driverName = o.driverName;
    this.licensePlate = o.licensePlate;
    this.createDateTime = o.createDateTime;
    this.transportTargetDate = o.transportTargetDate;
    this.materialDocument = o.materialDocument;
    this.transportStatus = o.transportStatus;
    this.receiveBy = o.receiveBy;
    this.receiveDateTime = o.receiveDateTime;
    this.documentNo = o.documentNo;
    this.documentDate = o.documentDate;
    this.materials = o.materials;
  }
}

export class ManageTransport extends Transport {
  totalRows?: number;
}

export class TransportStatus {
  transportStatusId: number;
  transportStatusName: string;

  constructor(o: TransportStatus) {
    this.transportStatusId = o.transportStatusId;
    this.transportStatusName = o.transportStatusName;
  }
}

export class UpdateTransportStatus extends TransportStatus {
  storeId: number;

  constructor(o: UpdateTransportStatus) {
    super(o);

    this.storeId = o.storeId;
  }
}

export class TransportMaterial extends Material {
  transportMaterialInfo: TransportMaterialInfo;
  receiveMaterialInfo: ReceiveMaterialInfo;

  /**
   *
   */
  constructor(o: TransportMaterial) {
    super(o);
    this.transportMaterialInfo = o.transportMaterialInfo;
    this.receiveMaterialInfo = o.receiveMaterialInfo;
  }
}

export class TransportMaterialInfo {
  suggestTotalTransport: number;
  totalTransport: number;
  transportRemark: string;

  /**
   *
   */
  constructor(o: TransportMaterialInfo) {
    this.suggestTotalTransport = o.suggestTotalTransport;
    this.totalTransport = o.totalTransport;
    this.transportRemark = o.transportRemark;
  }
}

export class ReceiveMaterialInfo {
  totalReceive: number;
  receiveRemark: string;

  /**
   *
   */
  constructor(o: ReceiveMaterialInfo) {
    this.totalReceive = o.totalReceive;
    this.receiveRemark = o.receiveRemark;
  }
}

export interface TransportDriver {
  companyName: string;
  companyContactName: string;
  companyContactTel: string;
  drivers: Driver[];
}

export interface Driver {
  companyName: string;
  carNo: string;
  licensePlate: string;
  driverName: string;
  driverTel: string;
}
