import { AuthService } from './auth.service';
import { ApiResponse } from './../models/api-response';
import { Profile } from './../models/profile';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { appConfig } from 'src/app/config';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private apiUrl = environment.apiBasePath;

  httpHeaders?: HttpHeaders | null;
  
  fetchToken() {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('apiToken')}`
    });
  }
  
  constructor(private http: HttpClient, private auth: AuthService, private route: Router) { }

  async get(url: string, urlParams: string = '', queryParams: string = '',body: any = null) {
    try {
      this.fetchToken();
      var apiResponse = await this.http.get<ApiResponse>(this.apiUrl + url + urlParams + queryParams, { headers: this.httpHeaders! }).toPromise();

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Unauthorized) {
        this.auth.logout()
        return;
      }

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Ok)
        return apiResponse.data;
    } catch (error) {
      return this.handleError(error);
    }
  }


  async post(url: string, urlParams: string = '', body: any = null) {
    try {
      this.fetchToken();
      
      var apiResponse = await this.http.post<ApiResponse>(this.apiUrl + url + urlParams, body, { headers: this.httpHeaders! }).toPromise();

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Unauthorized) {
        this.auth.logout()
        return;
      }

      if (apiResponse && apiResponse.statusCode)
        return apiResponse.statusCode === HttpStatusCode.Ok;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async put(url: string, urlParams: string = '', body: any = null) {
    try {
      this.fetchToken();
      
      var apiResponse = await this.http.put<ApiResponse>(this.apiUrl + url +  urlParams, body, { headers: this.httpHeaders! }).toPromise();

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Unauthorized) {
        this.auth.logout()
        return;
      }

      if (apiResponse && apiResponse.statusCode)
        return apiResponse.statusCode === HttpStatusCode.Ok;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async patch(url: string, urlParams: string = '', body: any = null) {
    try {
      this.fetchToken();
      
      var apiResponse = await this.http.patch<ApiResponse>(this.apiUrl + url + urlParams, body, { headers: this.httpHeaders! }).toPromise();

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Unauthorized) {
        this.auth.logout()
        return;
      }

      if (apiResponse && apiResponse.statusCode)
        return apiResponse.statusCode === HttpStatusCode.Ok;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async delete(url: string, urlParams: string = '') {
    try {
      this.fetchToken();
      
      var apiResponse = await this.http.delete<ApiResponse>(this.apiUrl + url + urlParams, { headers: this.httpHeaders! }).toPromise();

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Unauthorized) {
        this.auth.logout()
        return;
      }

      if (apiResponse && apiResponse.statusCode == HttpStatusCode.Ok)
        return apiResponse.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getProfile() {
    try {
      var accessToken = localStorage.getItem('accessToken');
      var response = await this.http.get<ApiResponse>(this.apiUrl + '/userAuthen', {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        })
      }).toPromise();

      if (response.statusCode == HttpStatusCode.Ok) {
        return response.data as Profile;
      } else {
        return null;
      }
    } catch (error) {
      this.handleError(error);
      return null;
    }
  }

  // Error handling 
  handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } if (error instanceof HttpErrorResponse && error.status == HttpStatusCode.Unauthorized) {
      errorMessage = 'Session สำหรับการใช้งานหมดอายุ กรุณาเข้าสู่ระบบใหม่อีกครั้งภาพหลัง';
      console.log(errorMessage);
      this.auth.logout(false);  
      localStorage.clear();
      window.location.href = '/';
      return window.location.reload();
    } if (error.error.message != '') {
      errorMessage = error.error.message;
    }
    else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    Swal.fire({
      icon: 'error',
      title: 'พบข้อผิดพลาด ไม่สามารถทำรายการต่อได้',
      text: `${errorMessage}`,
    })
    return throwError(errorMessage);
  }
}
