import { CreateBOMMappingModel } from 'src/app/core/services/bom-mapping/model';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiRequest, toQueryParam } from '../../models/api-request';
import { Materiallist, MaterialModel,MaterialUnit } from '../../models/material';
import { updateUnit} from '../../models/unit';
@Injectable({
  providedIn: 'root'
})
export class ManageMaterialService {

  constructor(private api: ApiService) { }
  async getMaterialList(query: ApiRequest) {
    return await this.api.get('/material', '', toQueryParam(query));
  }

  async createMaterial(request: MaterialModel) {
    return await this.api.post('/material', '', request);
  }

  async createMaterialList(request: Array<MaterialUnit> ) {
    return await this.api.post('/material/MaterialList', '', request);
  }


  async updateMaterial(id: number, request: MaterialModel) {
    return await this.api.put('/material', `/${id}`, request);
  }

  async deleteMaterial(id: number) {
    return await this.api.delete('/material', `/${id}`);
  }

  async updateStatusUnit(id: number, request: updateUnit) {
    return await this.api.put('/unit', `/${id}/updateRequestStatus`, request);
  }

  async ValidateMaterialList(request: Array<Materiallist> ) {
    return await this.api.post('/material/ValidateMaterial', '', request);
    
  }
}
