import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ApiRequest } from 'src/app/core/models/api-request';
import { FilterOptions } from 'src/app/core/models/filter';
import { Material } from 'src/app/core/models/material';
import { ModelType } from 'src/app/core/models/modelType';
import { Project } from 'src/app/core/models/project';
import { Store } from 'src/app/core/models/store';
import { WorkType } from 'src/app/core/models/workType';
import { DropdownService } from 'src/app/core/providers/dropdown.service';
import { TransportStatus } from 'src/app/core/services/transport/model';
import swal from 'sweetalert2';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  @Input() options: FilterOptions = new FilterOptions();
  @Output() fetchTable = new EventEmitter<ApiRequest>();

  queryParams!: ApiRequest;

  @ViewChild('dateRangeStart') dateRangeStart!: ElementRef<HTMLInputElement>;
  @ViewChild('dateRangeEnd') dateRangeEnd!: ElementRef<HTMLInputElement>;

  summaryFilteredText = '';

  searchText = '';
  startDate = '';
  endDate = '';

  dropdownProject: Array<Project> | null = null;
  dropdownStore: Array<Store> | null = null;
  dropdownTransportStatus: Array<TransportStatus> | null = null;
  dropdownWorkType: Array<WorkType> | null = null;
  dropdownModelType: Array<ModelType> | null = null;
  dropdownMaterial: Array<Material> | null = null;

  projectFC = new FormControl();
  projectOption: Project[] = [];
  filteredProjectOptions!: Observable<any>;

  storeFC = new FormControl();
  storeOption: Store[] = [];
  filteredStoreOptions!: Observable<any>;

  materialFC = new FormControl();
  materialOption: Material[] = [];
  filteredMaterialOptions!: Observable<any>;

  filteredStartDate = '';
  filteredEndDate = '';
  filteredProject: Project | null = null;
  filteredStore: Store | null = null;
  filteredTransportStatus: TransportStatus | null = null;
  filteredWorkType: WorkType | null = null;
  filteredModelType: ModelType | null = null;
  filteredDocumentCode = '';
  filteredUnitCode = '';
  filteredFlagOverBOM = false;
  filteredMaterialDocument = '';
  showZoneOnly = false;

  constructor(private dropdownService: DropdownService) {}

  async ngOnInit(): Promise<void> {
    this.initParam();
    this.dropdownProject = await this.dropdownService.getDropdownProjectList();
    this.dropdownStore = await this.dropdownService.getDropdownStore();
    this.dropdownTransportStatus =
      await this.dropdownService.getDropdownTransportStatus();
    this.dropdownWorkType =
      await this.dropdownService.getDropdownWorkTypeList();
    this.dropdownModelType =
      await this.dropdownService.getDropdownModelTypeList();
    this.dropdownMaterial = await this.dropdownService.getDropdownMaterial();
    this.projectOption = this.dropdownProject;
    this.storeOption = this.dropdownStore;
    this.materialOption = this.dropdownMaterial;

    this.filteredProjectOptions = this.projectFC.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.projectName)),
      map((projectName) =>
        projectName
          ? this.projectFilter(projectName)
          : this.projectOption.slice()
      )
    );

    this.filteredStoreOptions = this.storeFC.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.storeName)),
      map((storeName) =>
        storeName ? this.storeFilter(storeName) : this.storeOption.slice()
      )
    );

    this.filteredMaterialOptions = this.materialFC.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.materialCode)),
      map((material) =>
        material ? this.materialFilter(material) : this.materialOption.slice()
      )
    );
  }

  displayFn(project: Project): string {
    return project && project.projectCode + ' - ' + project.projectName
      ? project.projectCode + ' - ' + project.projectName
      : '';
  }

  displayStoreFn(store: Store): string {
    return store && store.storeCode + ' - ' + store.storeName
      ? store.storeCode + ' - ' + store.storeName
      : '';
  }

  displayMaterialFn(material: Material): string {
    return material && material.materialCode + ' - ' + material.materialName
      ? material.materialCode + ' - ' + material.materialName
      : '';
  }

  private projectFilter(name: string): Project[] {
    const filterValue = name.toLowerCase();
    const projects = this.dropdownProject!.filter((option) =>
      (option.projectCode + ' - ' + option.projectName)
        .toLowerCase()
        .includes(filterValue)
    );

    if (projects?.length == 1 && this.projectFC.value != null) {
      this.dropdownService
        .getDropdownModelTypeList((this.projectFC.value as Project).projectCode)
        .then((modelTypes) => {
          this.dropdownModelType = [...modelTypes];
        });
    }
    return projects;
  }

  private storeFilter(name: string): Store[] {
    const filterValue = name.toLowerCase();
    return this.storeOption.filter((option) =>
      (option.storeCode + option.storeName).toLowerCase().includes(filterValue)
    );
  }

  private materialFilter(name: string): Material[] {
    const filterValue = name.toLowerCase();
    return this.materialOption.filter((option) =>
      (option.materialCode + option.materialName)
        .toLowerCase()
        .includes(filterValue)
    );
  }

  initParam = () => {
    this.queryParams = new ApiRequest({
      search: '',
      startDate: '',
      endDate: '',
      sortBy: '',
      orderBy: '',
      start: 1,
      limit: 10,
    });
  };

  dateRangeChange(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement,
    isStart: boolean
  ) {
    if (isStart && dateRangeStart && dateRangeStart.value !== '') {
      this.filteredStartDate = moment(dateRangeStart.value, 'MM/DD/YYYY')
        .format('DD/MM/YYYY')
        .toString();
      this.dateRangeStart.nativeElement.value = this.filteredStartDate;
    }

    if (!isStart && dateRangeEnd && dateRangeEnd.value !== '') {
      this.filteredEndDate = moment(
        dateRangeEnd.value != '' ? dateRangeEnd.value : dateRangeStart.value,
        'MM/DD/YYYY'
      )
        .format('DD/MM/YYYY')
        .toString();
      this.dateRangeEnd.nativeElement.value = this.filteredEndDate;
    } else {
      this.dateRangeEnd.nativeElement.value = this.filteredStartDate;
      this.filteredEndDate = this.filteredStartDate;
    }
  }

  onValidateField(): boolean {
    let result = true;
    let arrayMessage = '';

    if (
      this.options.dropdownProject.visible &&
      this.options.dropdownProject.required &&
      !this.projectFC.value
    ) {
      arrayMessage = 'กรุณาเลือกโครงการ ก่อนทำรายการ';
    }

    if (
      this.options.dropdownStore.visible &&
      this.options.dropdownStore.required &&
      !this.storeFC.value
    ) {
      arrayMessage = 'กรุณาเลือก Store ก่อนทำรายการ';
    }

    if (arrayMessage !== '') {
      swal.fire('พบข้อผิดพลาด', arrayMessage, 'error');
      result = false;
    }

    return result;
  }

  clearFilters() {
    this.initParam();

    this.summaryFilteredText = '';

    if (this.options.search.visible) this.searchText = '';

    if (this.options.dateRangePicker.visible) {
      this.dateRangeStart.nativeElement.value = '';
      this.dateRangeEnd.nativeElement.value = '';
      this.filteredStartDate = '';
      this.filteredEndDate = '';
      this.startDate = '';
      this.endDate = '';
    }

    if (this.options.dropdownProject.visible) this.projectFC.setValue('');

    if (this.options.dropdownStore.visible) this.storeFC.setValue('');

    if (this.options.dropdownMaterial.visible) this.materialFC.setValue('');

    if (this.options.dropdownStore.visible) this.filteredStore = null;

    if (this.options.dropdownTransportStatus.visible)
      this.filteredTransportStatus = null;

    if (this.options.dropdownWorkType.visible) this.filteredWorkType = null;

    if (this.options.dropdownModelType.visible) this.filteredModelType = null;

    if (this.options.unitCodeInput.visible) this.filteredUnitCode = '';

    if (this.options.documentCodeInput.visible) this.filteredDocumentCode = '';

    if (this.options.checkBoxOverBOMFlag.visible)
      this.filteredFlagOverBOM = false;

    if (this.options.materialDocumentInput.visible)
      this.filteredMaterialDocument = '';

    if (this.options.showZoneOnly.visible) this.showZoneOnly = false;
  }
  applyFilters() {
    this.initParam();

    if (!this.onValidateField()) {
      return;
    }

    var filters: Map<string, any>[] = [];
    this.summaryFilteredText = '';

    if (this.options.search.visible && this.searchText !== '') {
      this.queryParams.search = this.searchText;
      this.summaryFilteredText += this.searchText
        ? ` / ${this.searchText}`
        : '';
    }

    if (
      this.options.dateRangePicker.visible &&
      this.startDate !== '' &&
      this.endDate !== ''
    ) {
      this.queryParams.startDate = this.filteredStartDate;
      this.queryParams.endDate = this.filteredEndDate;
      this.summaryFilteredText +=
        this.filteredStartDate && this.filteredEndDate
          ? ` / ${this.filteredStartDate} - ${this.filteredEndDate}`
          : '';
    }

    if (
      this.options.dropdownTransportStatus.visible &&
      this.filteredTransportStatus
    ) {
      let param = new Map<string, string>().set(
        'transportStatusId',
        this.filteredTransportStatus?.transportStatusId!.toString()
      );
      this.summaryFilteredText += this.filteredTransportStatus
        ? ` / ${this.filteredTransportStatus?.transportStatusName}`
        : '';
      filters.push(param);
    }

    if (
      this.options.dropdownStore.visible &&
      this.storeFC.value &&
      this.storeFC.value?.storeId !== null
    ) {
      let param = new Map<string, string>().set(
        'storeId',
        this.storeFC.value?.storeId!.toString()
      );
      this.summaryFilteredText +=
        ` / ${this.storeFC.value?.storeCode} - ${this.storeFC.value?.storeName}` ??
        '';
      filters.push(param);
    }

    if (
      this.options.dropdownProject.visible &&
      this.projectFC.value &&
      this.projectFC.value?.projectCode !== null
    ) {
      let param = new Map<string, string>().set(
        'projectCode',
        this.projectFC.value?.projectCode
      );
      this.summaryFilteredText +=
        ` / ${this.projectFC.value?.projectCode} - ${this.projectFC.value?.projectName}` ??
        '';
      filters.push(param);
    }

    if (this.options.dropdownWorkType.visible && this.filteredWorkType) {
      let param = new Map<string, string>().set(
        'workTypeId',
        this.filteredWorkType?.workTypeId!.toString()
      );
      this.summaryFilteredText +=
        ` / ${this.filteredWorkType?.workTypeName}` ?? '';
      filters.push(param);
    }

    if (this.options.dropdownMaterial.visible && this.filteredModelType) {
      let param = new Map<string, string>().set(
        'modelId',
        this.filteredModelType?.modelId!.toString()
      );
      this.summaryFilteredText +=
        ` / ${this.filteredModelType?.modelCode}` ?? '';
      filters.push(param);
    }

    if (
      this.options.dropdownMaterial.visible &&
      this.materialFC.value &&
      this.materialFC.value !== null
    ) {
      let param = new Map<string, string>().set(
        'materialId',
        this.materialFC.value?.materialId!.toString()
      );
      this.summaryFilteredText +=
        ` / ${this.materialFC.value?.materialCode} - ${this.materialFC.value?.materialName}` ??
        '';
      filters.push(param);
    }

    if (
      this.options.documentCodeInput.visible &&
      this.filteredDocumentCode != ''
    ) {
      let param = new Map<string, string>().set(
        'documentCode',
        this.filteredDocumentCode
      );
      filters.push(param);
    }

    if (this.options.checkBoxOverBOMFlag.visible && this.filteredFlagOverBOM) {
      let param = new Map<string, string>().set(
        'FlagOverBom',
        this.filteredFlagOverBOM.toString()
      );
      filters.push(param);
    }

    if (this.options.unitCodeInput.visible && this.filteredUnitCode) {
      let param = new Map<string, string>().set(
        'unitCode',
        this.filteredUnitCode.toString()
      );
      filters.push(param);
    }

    if (
      this.options.materialDocumentInput.visible &&
      this.filteredMaterialDocument != ''
    ) {
      let param = new Map<string, string>().set(
        'materialDocument',
        this.filteredMaterialDocument
      );
      filters.push(param);
    }

    if (this.options.showZoneOnly.visible) {
      let param = new Map<string, boolean>().set(
        'showZoneOnly',
        this.showZoneOnly
      );
      filters.push(param);
    }

    this.queryParams.filters = filters;
    console.log(this.queryParams);
    this.fetchTable.emit(this.queryParams);
  }
}
