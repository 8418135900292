<div class="container">
  <mat-toolbar color="primary">
    <button mat-icon-button (click)="onToggleDrawer.emit()">
      <mat-icon>menu</mat-icon>
    </button>
    <span><a class="logo-link" mt-button [routerLink]="['/main']">PCF Site warehouse</a></span>
    <span class="space"></span>
    <span style="margin-right: 10px">Hello, {{ displayName }}</span>
    <button mat-raised-button color="warn" (click)="logout()">
      ออกจากระบบ
    </button>
  </mat-toolbar>
</div>
