import { ManageMaterialService } from '../../../../core/services/manage-material/manage-material.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Project } from 'src/app/core/models/project';
import { BOMMapping } from 'src/app/core/models/bom';
import { Materiallist,MaterialModel } from 'src/app/core/models/material';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Material, MaterialUnit } from 'src/app/core/models/material';
import { DropdownMaterialUnit } from 'src/app/core/models/material-unit';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';
import { ApiRequest, toQueryParam } from 'src/app/core/models/api-request';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'app-Import-manage-material-dialog',
  templateUrl: './Import-manage-material-dialog.component.html',
  styleUrls: ['./Import-manage-material-dialog.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ImportManageMaterialDialogComponent implements OnInit, AfterViewInit {
  
  numRegex = /^-?\d*[.,]?\d{0,2}$/;
  @ViewChild(MatTable) table!: MatTable<Materiallist>;
  form = this.fb.group({
    uploadFile: new FormControl(null, [Validators.required])
  })
  version = 1;
  refNo = 0;
  workTypes?: DropdownMaterialUnit[];
  materials?: Material[];
  datalist = Array<Materiallist>();
  dataSource: Materiallist[] = [];
  btnDisplay : boolean = true;
  displayedColumns: string[] = [
    'no',
    'materialCode',
    'materialName',
    'materialUnitCode',
    'materialWeight',
    'errorMessage'
  
  ];

  totalRows = 1;
  queryParam = new ApiRequest({
    search: '',
    startDate: '',
    endDate: '',
    sortBy: '',
    orderBy: '',
    start: 1,
    limit: 10
  });

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ImportManageMaterialDialogComponent>,
    private ManageMaterialService: ManageMaterialService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {
    dropdownProject: Project[],
    dropdownWorkType: DropdownMaterialUnit[],
    bomMapping: BOMMapping,
    editValue: Materiallist,
    Materiallist:Materiallist[]
    
  }) {
 
  }
  ngAfterViewInit(): void {

    if (this.data.editValue) {
      var modelType = this.data.dropdownWorkType.find(o => o.materialUnitId == this.data.editValue.materialUnit.materialUnitId);
      this.form.controls['materialCode'].setValue(this.data.editValue.materialCode);
      this.form.controls['materialName'].setValue(this.data.editValue.materialName);
      this.form.controls['materialWeight'].setValue(this.data.editValue.materialWeight);
      this.form.controls['workTypes'].setValue(modelType);
      this.cdr.detectChanges();
    }
  }


  async ngOnInit() {
    this.workTypes = await this.data.dropdownWorkType;

  }

  async onSubmit() {

    

  var response: boolean = false;

  response = await this.ManageMaterialService.createMaterialList(this.datalist) as boolean;

  if (response == true) {
    this.snackBar.open('บันทึกข้อมูลสำเร็จ', 'ตกลง', { duration: 3000 })
    this.dialogRef.close();
  }
  }

  fetchTable() {
    var filters: Map<string, string>[] = [];
    
    this.queryParam.filters = filters;
    this.dataSource = (this.datalist) as Materiallist[];
    this.totalRows = this.dataSource.length > 0 ? this.datalist[0].totalRows! : 0;
    this.table!.renderRows();
  }

  onFileChange(evt: any) {

    
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.datalist = XLSX.utils.sheet_to_json(ws);
      console.log("data:",this.datalist);
      console.log(this.data.Materiallist);
      this.datalist.forEach(item => {
        var ErrorMessage = '';
        var check_MaterialCode = this.data.Materiallist.find(o => o.materialCode == item.materialCode);
        if(check_MaterialCode != null){
          ErrorMessage += 'รหัสวัสดุซ้ำในระบบ';
          this.btnDisplay = false;
        }

        var check_MaterialName = this.data.Materiallist.find(o => o.materialName== item.materialName);
        if(check_MaterialName != null){
          ErrorMessage += ' ชื่อวัสดุซ้ำในระบบ';
          this.btnDisplay = false;
        }

       


        item.errorMessage = ErrorMessage;
      });

      this.fetchTable();
    };

    //this.fetchTable();
    reader.readAsBinaryString(target.files[0]);
  }
}
