import { Injectable } from '@angular/core';
import { ModelType } from '../../models/modelType';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  constructor(private api: ApiService) { }

  async getList() {
    return await this.api.get('/model') as Array<ModelType>;
  }
}
