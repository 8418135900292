<h4 mat-dialog-title>สร้างวัสดุ</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div class="form-container">
      <mat-form-field appearance="standard">
        <mat-label>รหัสวัสดุ (ระบุได้ไม่เกิน 30 ตัวอักษร)</mat-label>
        <input type="text" matInput formControlName="materialCode" required />
        <mat-error *ngIf="form.controls['materialCode'].errors?.required">กรุณาระบุ รหัสวัสดุ</mat-error>
        <mat-error *ngIf="form.controls['materialCode'].errors?.maxlength != null">
          ระบุได้ไม่เกิน
          {{ form.controls["materialCode"].errors?.maxlength.requiredLength }}
          ตัวอักษร
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>ชื่อวัสดุ (ระบุได้ไม่เกิน 200 ตัวอักษร)</mat-label>
        <input type="text" matInput formControlName="materialName" required />
        <mat-error *ngIf="form.controls['materialName'].errors?.required">กรุณาระบุชื่อวัสดุ</mat-error>
        <mat-error *ngIf="form.controls['materialName'].errors?.maxlength != null">
          ระบุได้ไม่เกิน
          {{ form.controls["materialName"].errors?.maxlength.requiredLength }}
          ตัวอักษร
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>น้ำหนักวัสดุ : หน่วย(กิโลกรัม)</mat-label>
        <input type="text" matInput formControlName="materialWeight" required />
        <mat-error *ngIf="form.controls['materialWeight'].errors?.required">กรุณาระบุน้ำหนักวัสดุ</mat-error>
        <mat-error *ngIf="form.controls['materialWeight'].errors?.pattern">กรุณาระบุน้ำหนักวัสดุเป็นตัวเลข</mat-error>
      
      </mat-form-field>
      <mat-form-field appearance="standard">
        <mat-label>หน่วยวัสดุ</mat-label>
        <mat-select id="workTypes" formControlName="workTypes" required>
          <mat-option *ngFor="let workTypes of data.dropdownWorkType" [value]="workTypes" >
            {{ workTypes.materialUnitCode + "-" + workTypes.materialUnitName}} 
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['workTypes'].errors?.required">
          กรุณาระบุหน่วยวัสดุ
        </mat-error>
        <mat-error *ngIf="form.controls['workTypes'].errors?.pattern">
          กรุณาระบุหน่วยวัสดุเป็นตัวเลข
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>ยกเลิก</button>
    <button mat-button color="primary" type="submit" [disabled]="!form.valid">
      บันทึก
    </button>
  </div>
</form>
