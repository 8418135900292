import { ApiRequest } from "../../models/api-request";
import { WorkType } from "../../models/workType";

export class CreateBOMMappingModel {
    bomMappingId: number;
    projectCode: string;
    modelId: number;
    name: string;
    version: number;
    refNo: number;
    active: boolean;
    workTypes: CreateBOMMappingWorkType[];

    constructor(o: CreateBOMMappingModel) {
        this.bomMappingId = o.bomMappingId,
        this.projectCode = o.projectCode,
        this.modelId = o.modelId,
        this.name = o.name,
        this.version = o.version,
        this.refNo = o.refNo,
        this.active = o.active,
        this.workTypes = o.workTypes
    }
}

export class CreateBOMMappingWorkType {
    bomWorkTypeMaterialId: number;
    bomMappingId: number;
    workTypeMappingId?: number;
    workTypeId: number;
    materialId: number;
    qty: number;
    version: number;
    refNo: number;
    active: boolean;

    constructor(o: CreateBOMMappingWorkType) {
        this.bomWorkTypeMaterialId = o.bomWorkTypeMaterialId;
        this.bomMappingId = o.bomMappingId;
        this.workTypeMappingId = o.workTypeMappingId;
        this.workTypeId = o.workTypeId;
        this.materialId = o.materialId;
        this.qty = o.qty;
        this.version = o.version;
        this.refNo = o.refNo;
        this.active = o.active;
    }
}