import { Observable } from 'rxjs';
import { MaterialFormComponent } from './material-form/material-form.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  Component,
  ComponentFactoryResolver,
  Inject,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkType } from 'src/app/core/models/workType';
import { Material, MaterialUnit } from 'src/app/core/models/material';
import {
  IMappedMaterials,
  IMappedWorkTypeMaterial,
} from '../bom-mapping-dialog/bom-mapping-dialog.component';
import * as uuid from 'uuid';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
export class MappingMaterialItem {
  index!: number;
  bomWorkTypeMaterialId?: number;
  material!: Material;
  totalBOM!: number;
}

@Component({
  selector: 'app-mapping-worktype',
  templateUrl: './mapping-worktype.component.html',
  styleUrls: ['./mapping-worktype.component.scss'],
})
export class MappingWorktypeComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('materialFormContainer', { read: ViewContainerRef })
  private viewRef!: ViewContainerRef;

  formControlIndex = 0;
  form = this.fb.group({
    workType: new FormControl(null, Validators.required),
  });
  workTypeSearch = new FormControl(null);
  workTypeList!: Observable<WorkType[]>;

  constructor(
    private fb: FormBuilder,
    private cfr: ComponentFactoryResolver,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<MappingWorktypeComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      workTypes: WorkType[];
      materials: Material[];
      editValue?: IMappedWorkTypeMaterial;
      mappedWorkTypes?: WorkType[];
    }
  ) {}

  ngAfterViewInit(): void {
    if (this.data.editValue) {
      var workType = new WorkType({
        workTypeId: this.data.editValue!.workTypeId,
        workTypeCode: this.data.editValue!.workTypeCode,
        workTypeName: this.data.editValue!.workTypeName,
      });

      this.form.controls['workType'].setValue(workType);
      this.workTypeSearch.setValue(
        workType.workTypeCode + '-' + workType.workTypeName
      );

      this.form.updateValueAndValidity();

      for (let i = 0; i < this.data.editValue!.materials!.length; i++) {
        var material = this.data.editValue!.materials![i];
        var item = new MappingMaterialItem();
        item.bomWorkTypeMaterialId = material.bomWorkTypeMaterialId ?? 0;
        item.material = new Material({
          materialId: material.materialId,
          materialCode: material.materialCode,
          materialName: material.materialName,
          materialUnit: new MaterialUnit({
            materialUnitId: material.materialUnitId,
            materialUnitCode: material.materialUnitCode,
            materialUnitName: material.materialUnitName,
          }),
          materialBOM: null,
          materialStock: null,
        });
        item.totalBOM = material.totalBOM;

        this.onAddMaterialForm(item);
      }

      this.cdr.detectChanges();
    }
  }

  ngOnDestroy(): void {}

  ngOnInit(): void {
    if (this.data.mappedWorkTypes != null) {
      this.data.workTypes = this.data.workTypes.filter(
        (workType) =>
          this.data.mappedWorkTypes!.find(
            (o) => o.workTypeId == workType.workTypeId
          ) == null
      );
    }
    this.workTypeList = this.workTypeSearch.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value))
    );
  }

  private _filter(value: string): WorkType[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.data.workTypes.filter((workType) =>
        (workType.workTypeCode + '-' + workType.workTypeName)
          .toLowerCase()
          .includes(filterValue)
      );
    } else {
      return this.data.workTypes.slice();
    }
  }

  async onSubmit() {
    if (this.form.valid) {
      let skipDup = false;
      var result = new IMappedWorkTypeMaterial();
      var workType = this.form.controls['workType'].value as WorkType;

      result.uuid = this.data.editValue?.uuid ?? uuid.v4();
      result.bomMappingId = this.data.editValue?.bomMappingId ?? 0;
      result.workTypeId = workType.workTypeId;
      result.workTypeCode = workType.workTypeCode;
      result.workTypeName = workType.workTypeName;
      result.workTypeMappingId = this.data.editValue?.workTypeMappingId ?? 0;
      result.version = this.data.editValue?.version ?? 0;
      result.usedFlag = this.data.editValue?.usedFlag ?? false;
      result.refNo = this.data.editValue?.refNo ?? 0;
      result.materials = [];

      for (var i = 1; i <= this.formControlIndex; i++) {
        let key = `material-form-${i}`;
        if (!this.form.controls[key]) continue;

        var inputMaterial = this.form.controls[key]
          .value as MappingMaterialItem;

        const matItem = result.materials.find((mat) => mat.materialId === inputMaterial.material.materialId)

        if (matItem) {
          skipDup = true;
          continue;
        }

        var mappedMaterial = new IMappedMaterials();

        mappedMaterial.bomWorkTypeMaterialId =
          inputMaterial.bomWorkTypeMaterialId ?? 0;
        mappedMaterial.materialId = inputMaterial.material.materialId;
        mappedMaterial.materialCode = inputMaterial.material.materialCode;
        mappedMaterial.materialName = inputMaterial.material.materialName;
        mappedMaterial.materialUnitId =
          inputMaterial.material.materialUnit?.materialUnitId!;
        mappedMaterial.materialUnitCode =
          inputMaterial.material.materialUnit?.materialUnitCode!;
        mappedMaterial.materialUnitName =
          inputMaterial.material.materialUnit?.materialUnitName!;
        mappedMaterial.totalBOM = inputMaterial.totalBOM;

        result.materials.push(mappedMaterial);
      }

      this.dialogRef.close(result);

      if (skipDup) await Swal.fire('รายการข้อมูลซ้ำ', 'ข้อมูลที่ซ้ำจะถูกนำออกจากระบบ', 'warning');
    }
  }

  workTypeSelect(event: MatAutocompleteSelectedEvent): void {
    var value = event.option.value;
    var viewValue = event.option.viewValue;

    this.workTypeSearch.setValue(viewValue);

    this.form.controls['workType'].setValue(value);
    this.form.updateValueAndValidity();
  }

  onAddMaterialForm(item?: MappingMaterialItem) {
    this.formControlIndex++;

    this.form.addControl(
      `material-form-${this.formControlIndex}`,
      new FormControl(item, Validators.required)
    );

    const componentFactory = this.cfr.resolveComponentFactory(
      MaterialFormComponent
    );
    const componentRef = this.viewRef.createComponent(componentFactory);
    componentRef.instance.materials = this.data.materials;
    componentRef.instance.formIndex = this.formControlIndex;
    componentRef.instance.material = item?.material;
    componentRef.instance.totalBOM = item?.totalBOM;
    componentRef.instance.bomWorkTypeMaterialId =
      item?.bomWorkTypeMaterialId ?? 0;

    componentRef.instance.mappingMaterial.subscribe((event) => {
      var key = `material-form-${event.index}`;
      var value = null;

      if (event.material != null && event.totalBOM != null) {
        value = event;
      }

      var curForm = this.form.controls[key];
      curForm.setValue(value);
      curForm.updateValueAndValidity();
    });

    componentRef.instance.removeComponent.subscribe((event) => {
      var key = `material-form-${event}`;
      this.form.removeControl(key);
      componentRef.destroy();
    });
  }
}
