import { filter } from 'rxjs/operators';
import { Materiallist } from './material';
export class ApiRequest {
  search: string;
  filters?: Map<string, any>[];
  sortBy: string;
  orderBy: string;
  start: number = 1;
  limit: number = 10;
  startDate: string;
  endDate: string;

  /**
   *
   */
  constructor(o: ApiRequest) {
    this.search = o.search;
    this.sortBy = o.sortBy;
    this.filters = o.filters;
    this.orderBy = o.orderBy;
    this.start = o.start;
    this.limit = o.limit;
    this.startDate = o.startDate;
    this.endDate = o.endDate;
    this.endDate = o.endDate;
  }
}

export function toQueryParam(o?: ApiRequest | null) {
  var query = '';

  if (o?.search && o?.search != '') {
    query += '&search=' + o?.search;
  }
  if (o?.filters && o?.filters != null && o?.filters.length > 0) {
    o?.filters.forEach((element) => {
      element.forEach((value: string, key: string) => {
        query += `&${key}=${value}`;
      });
    });
  }
  if (o?.sortBy && o?.sortBy != '') {
    query += '&sortBy=' + o?.sortBy;
  }
  if (o?.orderBy && o?.orderBy != '') {
    query += '&orderBy=' + o?.orderBy;
  }
  if (o?.start && o?.start != null) {
    query += '&start=' + o?.start;
  }
  if (o?.limit && o?.limit != null) {
    query += '&limit=' + o?.limit;
  }
  if (o?.start === 0 && o?.limit === 0) {
    query += '&start=0&limit=0';
  }
  if (o?.startDate && o?.startDate != '') {
    query += '&startDate=' + o?.startDate;
  }
  if (o?.endDate && o?.endDate != '') {
    query += '&endDate=' + o?.endDate;
  }

  query = query.replace('&', '');

  if (query != '') {
    query = '?' + query;
  }

  return query;
}
