import { ApiResponse } from './../../core/models/api-response';
import { Profile } from '../../core/models/profile';
import { ApiService } from '../../core/services/api.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChange,
  ChangeDetectorRef,
} from '@angular/core';
import { AuthService } from '../../core/services/auth.service';
import { filter } from 'rxjs/operators';
import {
  EventMessage,
  EventType,
  AuthenticationResult,
} from '@azure/msal-browser';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  @Input() isAuthenticated = false;
  authErrorMessage = '';

  constructor(
    private authService: AuthService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
    private api: ApiService
  ) {}

  ngOnInit(): void {
    var accessToken = localStorage.getItem('profile');
    if (accessToken) {
      this.router.navigate(['main']);
    }

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType == EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.HANDLE_REDIRECT_END
        )
      )
      .subscribe(async (result: EventMessage) => {
        if (result.eventType === EventType.LOGIN_SUCCESS) {
          try {
            var payload = result.payload as AuthenticationResult;
            localStorage.setItem('accessToken', payload.accessToken);

            Swal.showLoading();
            var profile = await this.api.getProfile();
            Swal.close();
            if (!profile) {
              this.authErrorMessage = `เข้าสู่ระบบไม่สำเร็จ โปรดลองอีกครั้ง`;
              localStorage.clear();
              return;
            }

            if (
              profile.role.roleCode === 'PSW1000' ||
              profile.role.roleCode === 'PSW2000' ||
              profile.role.roleCode === 'PSW2100' ||
              profile.role.roleCode === 'PSW9000'
            ) {
              this.isAuthenticated = true;
              localStorage.setItem('account', JSON.stringify(payload.account));
              localStorage.setItem('profile', JSON.stringify(profile!));
              localStorage.setItem('apiToken', profile!.token);
              this.authErrorMessage = '';
              this.router.navigate(['../main']);
            } else {
              this.isAuthenticated = false;
              localStorage.removeItem('accessToken');
              localStorage.removeItem('account');
              localStorage.removeItem('profile');
              localStorage.removeItem('apiToken');
              this.authErrorMessage =
                'ไม่มีสิทธิเข้าใช้งานระบบ โปรดติดต่อผู้ดูแลระบบ';
            }
          } catch (error: any) {
            this.authErrorMessage = error.message.toString();
          }
        }
      });
  }

  async login(): Promise<void> {
    localStorage.clear();
    await this.authService.loginPopup();
  }
}
