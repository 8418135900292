import { CreateBOMMappingModel } from 'src/app/core/services/bom-mapping/model';
import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { ApiRequest, toQueryParam } from '../../models/api-request';

@Injectable({
  providedIn: 'root',
})
export class BomMappingService {
  constructor(private api: ApiService) {}

  async createBOMMapping(requestBody: CreateBOMMappingModel) {
    return await this.api.post('/bom', '', requestBody);
  }

  async getBOMMapping(query: ApiRequest) {
    return await this.api.get('/bom', '', toQueryParam(query));
  }

  async deleteBOMMapping(id: number) {
    return await this.api.delete('/bom', `/${id}`);
  }

  async updateBOMMaping(id: number, requestBody: CreateBOMMappingModel) {
    return await this.api.put('/bom', `/${id}`, requestBody);
  }

  async activeBOMMapping(id: number) {
    return await this.api.patch('/bom', `/${id}`, null);
  }

  async getRequestMaterialWithoutBOM(projectCode: string) {
    let query: ApiRequest = {
      search: '',
      sortBy: '',
      orderBy: '',
      start: 0,
      limit: 0,
      startDate: '',
      endDate: '',
      filters: [],
    };

    if (projectCode && projectCode !== '') {
      let param = new Map<string, string>().set('projectCode', projectCode);
      query.filters!.push(param);
    }
    return await this.api.get('/request/WithoutBOM', toQueryParam(query));
  }

  async mergeRequestWithoutBOM(request: any) {
    return await this.api.patch('/request/WithoutBOM', '', request);
  }
}
