import { MaterialModule } from './../../shared/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BomMappingRoutingModule } from './bom-mapping-routing.module';
import { BomMappingComponent } from './bom-mapping.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BomMappingDialogComponent } from './bom-mapping-dialog/bom-mapping-dialog/bom-mapping-dialog.component';
import { MappingWorktypeComponent } from './bom-mapping-dialog/mapping-worktype/mapping-worktype.component';
import { MaterialFormComponent } from './bom-mapping-dialog/mapping-worktype/material-form/material-form.component';
import { FiltersModule } from 'src/app/shared/filters/filters.module';


@NgModule({
  declarations: [
    BomMappingComponent,
    BomMappingDialogComponent,
    MappingWorktypeComponent,
    MaterialFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BomMappingRoutingModule,
    MaterialModule,
    FiltersModule
  ]
})
export class BomMappingModule { }
