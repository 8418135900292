import {
  CreateBOMMappingModel,
  CreateBOMMappingWorkType,
} from 'src/app/core/services/bom-mapping/model';
import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { BomMappingService } from './../../core/services/bom-mapping/bom-mapping.service';
import { DropdownService } from './../../core/providers/dropdown.service';
import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Project } from 'src/app/core/models/project';
import { WorkType } from 'src/app/core/models/workType';
import { ModelType } from 'src/app/core/models/modelType';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { BomMappingDialogComponent } from './bom-mapping-dialog/bom-mapping-dialog/bom-mapping-dialog.component';
import { ApiRequest, toQueryParam } from 'src/app/core/models/api-request';
import { BOMMapping } from 'src/app/core/models/bom';
import { MatTable } from '@angular/material/table';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { FilterOptions } from 'src/app/core/models/filter';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-bom-mapping',
  templateUrl: './bom-mapping.component.html',
  styleUrls: ['./bom-mapping.component.scss'],
})
export class BomMappingComponent implements OnInit {
  @ViewChild('paginator') paginator!: MatPaginator;
  dropdownProject = Array<Project>();
  dropdownWorkType = Array<WorkType>();
  dropdownModelType = Array<ModelType>();
  @ViewChild(MatTable) table!: MatTable<BOMMapping>;

  displayedColumns: string[] = [
    'no',
    'name',
    'projectName',
    'modelCode',
    'version',
    'updateDate',
    'updateBy',
    'actions',
  ];
  dataSource: BOMMapping[] = [];

  totalRows = 1;
  queryParam = new ApiRequest({
    search: '',
    startDate: '',
    endDate: '',
    sortBy: '',
    orderBy: '',
    start: 1,
    limit: 10,
  });

  filterOptions = new FilterOptions();

  constructor(
    private dropdown: DropdownService,
    private dialog: MatDialog,
    private bomMappingService: BomMappingService
  ) {}

  async ngOnInit(): Promise<void> {
    this.filterOptions.dropdownProject.visible = true;
    this.filterOptions.dropdownWorkType.visible = true;
    this.filterOptions.dropdownModelType.visible = true;
    this.dropdownProject = await this.dropdown.getDropdownProjectList();
    this.dropdownWorkType = await this.dropdown.getDropdownWorkTypeList();
    this.dropdownModelType = await this.dropdown.getDropdownModelTypeList();
    await this.fetchTable(this.queryParam);
  }

  resetPaginator() {
    this.queryParam.start = 1;
    this.queryParam.limit = 10;
    this.paginator.firstPage();
  }

  async fetchTable(queryParam: ApiRequest) {
    Swal.showLoading();
    this.queryParam = queryParam;
    this.dataSource = (await this.bomMappingService.getBOMMapping(
      this.queryParam
    )) as BOMMapping[];
    this.totalRows =
      this.dataSource.length > 0 ? this.dataSource[0].totalRows! : 0;
    this.table!.renderRows();
    Swal.close();
  }
  displayFn(user: Project): string {
    return user && user.projectCode + ' - ' + user.projectName
      ? user.projectCode + ' - ' + user.projectName
      : '';
  }

  onCreate(item?: BOMMapping) {
    if (item && !item.active) return;

    const dialogRef = this.dialog.open(BomMappingDialogComponent, {
      width: '100%',
      disableClose: true,
      autoFocus: false,
      data: {
        dropdownProject: this.dropdownProject,
        dropdownModelType: this.dropdownModelType,
        dropdownWorkType: this.dropdownWorkType,
        bomMapping: item,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.fetchTable(this.queryParam);
    });
  }

  async onDelete(item: BOMMapping) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'ยืนยันที่จะลบข้อมูลใช่หรือไม่ ?',
        content: `สูตร BOM '${item.name}' จะถูกปรับสถานะเป็น 'ปิดการใช้งาน'`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        await this.bomMappingService.deleteBOMMapping(item.bomMappingId);
      }
      await this.fetchTable(this.queryParam);
    });
  }

  async onUpdateStatus(item: BOMMapping) {
    if (item.active) {
      await this.onDelete(item);
    } else {
      await this.onActive(item);
    }
  }

  async onActive(item: BOMMapping) {
    Swal.showLoading();
    await this.bomMappingService.activeBOMMapping(item.bomMappingId);
    Swal.close();
    await this.fetchTable(this.queryParam);
  }

  async onUpdate(item: BOMMapping) {
    let workTypes: CreateBOMMappingWorkType[] = [];

    let updateBOMMapping = new CreateBOMMappingModel({
      bomMappingId: item.bomMappingId,
      projectCode: item.projectCode,
      modelId: item.modelId,
      name: item.name,
      version: item.version,
      refNo: item.refNo,
      active: item.active,
      workTypes: workTypes,
    });

    await this.bomMappingService.updateBOMMaping(
      item.bomMappingId,
      updateBOMMapping
    );
    await this.fetchTable(this.queryParam);
  }
}
