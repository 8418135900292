<h4 mat-dialog-title>สร้างวัสดุ</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <div class="form-container">
     
      <div class="row">
        <input type="file" (change)="onFileChange($event)" multiple="false"  formControlName="uploadFile"/>

        <a target="_self" href="../../../assets/downloads/Template_Materail.xlsx" download="Template_Materail.xlsx"><span>Download Template</span></a>
        <div class="table-responsive">
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%;">
            <ng-container matColumnDef="no">
              <th mat-header-cell *matHeaderCellDef> ลำดับ </th>
              <td mat-cell *matCellDef="let element; let i = index"> {{((queryParam.start - 1) * queryParam.limit) + (i + 1)}} </td>
            </ng-container>
      
            <ng-container matColumnDef="materialCode">
              <th mat-header-cell *matHeaderCellDef > รหัสวัสดุ </th>
              <td mat-cell *matCellDef="let element"> {{element.materialCode}} </td>
            </ng-container>
      
         
            <ng-container matColumnDef="materialName">
              <th mat-header-cell *matHeaderCellDef> ชื่อวัสดุ </th>
              <td mat-cell *matCellDef="let element"> {{element.materialName}}</td>
            </ng-container>
    
            <ng-container matColumnDef="materialUnitCode">
              <th mat-header-cell *matHeaderCellDef> หน่วยวัสดุ </th>
              <td mat-cell *matCellDef="let element"> {{element.materialUnitCode}}</td>
            </ng-container>

            <ng-container matColumnDef="materialWeight">
              <th mat-header-cell *matHeaderCellDef> น้ำหนักวัสดุ </th>
              <td mat-cell *matCellDef="let element"> {{element.materialWeight}}</td>
            </ng-container>

            <ng-container matColumnDef="errorMessage">
              <th mat-header-cell *matHeaderCellDef> แจ้งเตือน </th>
              <td mat-cell *matCellDef="let element"> <font color="red">{{element.errorMessage}}</font></td>
            </ng-container>
  
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
           
          </table>

        </div>
       
    </div>
     
     
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>ยกเลิก</button>
    <button mat-button color="primary" type="submit" [disabled]="!btnDisplay">
      Upload
    </button>
  </div>
</form>
