import { ManageWorkType } from 'src/app/core/services/worktype/model';
import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { WorkType } from '../../models/workType';
import { ApiRequest, toQueryParam } from '../../models/api-request';

@Injectable({
  providedIn: 'root'
})
export class WorkTypeService {

  constructor(private api: ApiService) { }

  async getList(query?: ApiRequest) {
    return await this.api.get('/workType', '', query != null ? toQueryParam(query) : '');
  }

  async createWorkType(request: ManageWorkType) {
    return await this.api.post('/workType', '', request);
  }

  async updateWorkType(id: number, request: ManageWorkType) {
    return await this.api.put('/workType', `/${id}`, request);
  }
}
