import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ManageMaterialRoutingModule } from './manage-material-routing.module';
import { ManageMaterialComponent } from './manage-material.component';
import { MaterialModule } from './../../shared/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageMaterialDialogComponent } from './manage-material-dialog/manage-material-dialog/manage-material-dialog.component';
import { HttpClientModule } from '@angular/common/http';
import { ImportManageMaterialDialogComponent } from './Import-manage-material-dialog/Import-manage-material-dialog/Import-manage-material-dialog.component';
import { FiltersModule } from 'src/app/shared/filters/filters.module';
import { FiltersComponent } from 'src/app/shared/filters/filters.component';
@NgModule({
  declarations: [
    ManageMaterialComponent,
    ManageMaterialDialogComponent,
    ImportManageMaterialDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ManageMaterialRoutingModule,
    HttpClientModule,
    FiltersModule
  ]
})
export class ManageMaterialModule { }
