import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MappingMaterialItem } from './../mapping-worktype.component';
import { AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Material } from 'src/app/core/models/material';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-material-form',
  templateUrl: './material-form.component.html',
  styleUrls: ['./material-form.component.scss']
})
export class MaterialFormComponent implements OnInit, AfterViewInit {
  @Input() materials?: Material[];
  @Input() bomWorkTypeMaterialId?: number;
  @Input() formIndex?: number;
  @Input() material?: Material | null = null;
  @Input() totalBOM?: number = 0;

  materialSearch = new FormControl(null);
  materialList!: Observable<Material[]>;

  form = this.fb.group({
    material: new FormControl(null, Validators.required),
    totalBOM: new FormControl(0, [Validators.required, Validators.pattern("^\\d+(?:\\.\\d{1,2})?$"), this.reachToMaxOfTotalBOM()])
  })

  saved = false;

  @Output() mappingMaterial = new EventEmitter<MappingMaterialItem>();
  @Output() removeComponent = new EventEmitter<number>();

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    if (this.material) {
      this.form.controls['material'].setValue(this.material);
      this.materialSearch.setValue((this.material.materialCode + '-' + this.material.materialName + `(${this.material.materialUnit?.materialUnitName})`));
      this.form.controls['totalBOM'].setValue(this.totalBOM);
      
      this.form.updateValueAndValidity();
      this.cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(
      result => this.saved = false
    );

    this.materialList = this.materialSearch.valueChanges.pipe(
      startWith(''),
      map((value: string) => this._filter(value))
    );
  }


  private _filter(value: string): Material[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.materials!.filter(mat => (mat.materialCode + '-' + mat.materialName + `(${mat.materialUnit?.materialUnitName})`).toLowerCase().includes(filterValue));
    } else {
      return this.materials!.slice();
    }
  }

  valueSelect(event: MatAutocompleteSelectedEvent): void {
    var value = event.option.value;
    var viewValue = event.option.viewValue;

    this.materialSearch.setValue(viewValue);

    this.form.controls['material'].setValue(value);
    this.form.updateValueAndValidity();
  }

  reachToMaxOfTotalBOM(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (control.value !== undefined && (isNaN(control.value) || control.value > 99999.99)) {
            return { 'reachToMaxOfTotalBOM': true };
        }
        return null;
    };
  }

  onSubmit() {
    var mappedMaterial = new MappingMaterialItem();
    mappedMaterial.index = this.formIndex!;
    mappedMaterial.bomWorkTypeMaterialId = this.bomWorkTypeMaterialId ?? 0;

    if (!this.form.valid) {
      this.saved = false;
      this.mappingMaterial.next(mappedMaterial);
      return;
    }

    mappedMaterial.material = this.form.controls['material'].value as Material;
    mappedMaterial.totalBOM = this.form.controls['totalBOM'].value as number;
    this.saved = true;
    this.mappingMaterial.next(mappedMaterial);
  }

  onRemoveMaterialForm() {
    this.removeComponent.next(this.formIndex);
  }

  compareObject(tempMaterial: Material, bindMaterial: Material) {
    return tempMaterial && bindMaterial && tempMaterial.materialId === bindMaterial.materialId;
  }
}
