import { BomMappingModule } from './../bom-mapping/bom-mapping.module';
import { ManageMaterialModule } from './../manage-material/manage-material.module';
import { MaterialModule } from '../../shared/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
@NgModule({
  declarations: [
    MainComponent,
    ToolbarComponent,
  ],
  imports: [
    CommonModule,
    MainRoutingModule,
    MaterialModule,
    ManageMaterialModule,
    BomMappingModule
  ],
  exports: [
    MainComponent,
  ]
})
export class MainModule { }