import { ApiService } from './../api.service';
import { Injectable } from '@angular/core';
import { Material } from '../../models/material';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(private api: ApiService) { 
    
  }

  async getList() {
    return await this.api.get('/material') as Material[];
  }
}
