import { Profile } from './../../../core/models/profile';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from 'src/app/core/services/auth.service';
import { ApiService } from 'src/app/core/services/api.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Output() onToggleDrawer = new EventEmitter();
  displayName = '';

  constructor(private authService: AuthService, private api: ApiService) { }

  ngOnInit(): void {
    var profile = (JSON.parse(localStorage.getItem('profile')!) as Profile);
    this.displayName = profile.firstName + ' ' + profile.lastName! + '(' + profile.role.roleName + ')';
  }

  logout(): void {
    this.clearAccessToken();

    localStorage.removeItem('accessToken');
    localStorage.removeItem('account');
    localStorage.removeItem('apiToken');
    localStorage.removeItem('profile');
    this.authService.logout(true);
  }


  clearAccessToken() {
    this.api.delete('/userAuthen');
  }
}
