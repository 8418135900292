<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="material-form-container">
    <mat-form-field class="material-field" appearance="standard">
      <mat-label>วัสดุ</mat-label>
      <input
        type="text"
        placeholder="เลือกวัสดุ"
        matInput
        [formControl]="materialSearch"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="valueSelect($event)"
      >
        <mat-option
          *ngFor="let material of materialList | async"
          [value]="material"
        >
          {{ material.materialCode + " - " + material.materialName + " (" + material!.materialUnit!.materialUnitName + ")"}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <span class="tiny-space"></span>

    <mat-form-field class="totalBOM-field" appearance="standard">
      <mat-label>จำนวน</mat-label>
      <input
        id="totalBOM"
        matInput
        formControlName="totalBOM"
        type="number"
        required
      />
    </mat-form-field>

    <span class="tiny-space"></span>

    <div class="action-field">
      <button
        mat-icon-button
        [color]="!saved ? 'primary' : 'success'"
        type="submit"
      >
        <mat-icon>check</mat-icon>
      </button>
      <button
        mat-icon-button
        color="warn"
        type="button"
        (click)="onRemoveMaterialForm()"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</form>
