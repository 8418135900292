<mat-card>
  <h4><b>จัดการวัสดุ</b></h4>

  <app-filters (fetchTable)="fetchTable($event)"></app-filters>

  <!-- filter -->
  <!-- <div style="margin-bottom: 20px">
      <mat-accordion>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>กรองข้อมูล</mat-panel-title>
            <mat-panel-description>{{summaryFilteredText}}</mat-panel-description>
          </mat-expansion-panel-header>
          <div class="layout-three-column">
            <div>
              <mat-form-field appearance="standard">
                <mat-label>ค้นหา</mat-label>
                <input matInput type="text" placeholder="ตย. พิมพ์เพื่อค้นหา" [(ngModel)]="searchText"/>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="standard">
                <mat-label>ระหว่างวันที่</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matInput matStartDate placeholder="วันที่เริ่ม" disabled [(ngModel)]="startDate" #dateRangeStart 
                  (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd, true)"/>/>
                  <input matInput matEndDate placeholder="วันที่สิ้นสุด" disabled [(ngModel)]="endDate" #dateRangeEnd
                    (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd, false)"/>
                </mat-date-range-input>
                <mat-datepicker-toggle
                  matSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker
                  #picker
                  disabled="false"
                ></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div></div>
          </div>
          <div class="layout-full-right">
            <button mat-stroked-button color="warn" (click)="clearFilters()">ล้าง</button>
            <span class="tiny-space"></span>
            <button mat-stroked-button color="primary" (click)="applyFilters()">ปรับใช้</button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div> -->

  <!-- ----- end filter ----- -->
  <button mat-flat-button color="primary" (click)="onCreate()">สร้าง</button>
  <span class="tiny-space"></span>
  <button mat-flat-button color="primary" (click)="ImportExcel()">
    นำเข้า
  </button>
  <span class="tiny-space"></span>
  <!-- <button mat-flat-button color="primary" type="file">นำเข้า</button>  -->

  <!-- <div class="form-group">
      <label for="file">Choose File</label>
      <input type="file"
      #inputFile (change)="onChange($event)">
  </div> -->

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      style="width: 100%"
    >
      <ng-container matColumnDef="no">
        <th mat-header-cell *matHeaderCellDef style="width: 40px">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" class="rowNumber">
          {{ (queryParam.start - 1) * queryParam.limit + (i + 1) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="materialCode">
        <th mat-header-cell *matHeaderCellDef style="width: 40px">รหัสวัสดุ</th>
        <td mat-cell *matCellDef="let element">{{ element.materialCode }}</td>
      </ng-container>

      <ng-container matColumnDef="materialName">
        <th mat-header-cell *matHeaderCellDef>ชื่อวัสดุ</th>
        <td mat-cell *matCellDef="let element">{{ element.materialName }}</td>
      </ng-container>

      <ng-container matColumnDef="materialWeight">
        <th mat-header-cell *matHeaderCellDef style="width: 40px">
          น้ำหนักวัสดุ
        </th>
        <td mat-cell *matCellDef="let element" class="numberFormat">
          {{ element.materialWeight | number: "1.2-2" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="materialUnitName">
        <th mat-header-cell *matHeaderCellDef style="width: 60px">
          ชื่อหน่วยวัสดุ
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.materialUnit.materialUnitName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="updateDate">
        <th mat-header-cell *matHeaderCellDef style="width: 60px">
          แก้ไขล่าสุด
        </th>
        <td mat-cell *matCellDef="let element">{{ element.updateDate }}</td>
      </ng-container>
      <ng-container matColumnDef="updateBy">
        <th mat-header-cell *matHeaderCellDef>แก้ไขโดย</th>
        <td mat-cell *matCellDef="let element">{{ element.updateBy }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef style="width: 100px">จัดการ</th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            type="button"
            (click)="onCreate(element)"
            [disabled]="!element.active"
          >
            <mat-icon>edit</mat-icon>
          </button>

          <mat-slide-toggle
            color="primary"
            [checked]="element.active"
            (click)="onUpdateStatus(element)"
          >
          </mat-slide-toggle>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="totalRows"
      [pageSize]="queryParam.limit"
      [pageSizeOptions]="[10, 20, 50, 100]"
      (page)="
        queryParam.start = $event.pageIndex + 1;
        queryParam.limit = $event.pageSize;
        fetchTable(queryParam)
      "
      showFirstLastButtons
      #paginator
    >
    </mat-paginator>
  </div>
</mat-card>
