<h4 mat-dialog-title>ตั้งค่าหมวดงาน</h4>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="mat-dialog-content">
    <div class="form-container">
      <mat-form-field appearance="standard">
        <mat-label>หมวดงาน</mat-label>
        <input type="text"
          placeholder="เลือกหมวดงาน"
          matInput
          [formControl]="workTypeSearch"
          [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="workTypeSelect($event)">
          <mat-option *ngFor="let workType of workTypeList | async" [value]="workType">
            {{ workType.workTypeCode + " - " + workType.workTypeName }}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="form.controls['workType'].errors?.required"
          >กรุณาระบุหมวดงาน</mat-error
        >
      </mat-form-field>

      <ng-template #materialFormContainer>

      </ng-template>

      <!-- <div class="material-form-container" #materialFormContainer>
        <mat-form-field class="material-field" appearance="standard">
          <mat-label>วัสดุ</mat-label>
          <mat-select id="material" formControlName="material" required>
            <mat-option
              *ngFor="let material of data.materials"
              [value]="material"
            >
              {{ material.materialCode + " - " + material.materialName }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <span class="tiny-space"></span>

        <mat-form-field class="totalBOM-field" appearance="standard">
          <mat-label>จำนวน</mat-label>
          <input
            id="totalBOM"
            matInput
            formControlName="totalBOM"
            type="number"
            required
          />
        </mat-form-field>

        <span class="tiny-space"></span>

        <button
          mat-icon-button
          color="warn"
          type="button"
          (click)="onRemoveMaterialForm(1)"
        >
          <mat-icon>delete</mat-icon>
        </button>
      </div> -->

      <button
        mat-flat-button
        color="primary"
        type="button"
        (click)="onAddMaterialForm()"
      >
        <mat-icon>add</mat-icon> เพิ่มวัสดุ
      </button>

      <div mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>ยกเลิก</button>
        <button
          mat-button
          color="primary"
          type="submit"
          [disabled]="!form.valid"
        >
          บันทึก
        </button>
      </div>
    </div>
  </div>
</form>
