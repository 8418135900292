import { MsalGuard } from '@azure/msal-angular';
import { MainComponent } from './main.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'bom-mapping',
        loadChildren: () =>
          import('../bom-mapping/bom-mapping.module').then(
            (m) => m.BomMappingModule
          ),
      },
      {
        path: 'manage-material',
        loadChildren: () =>
          import('../manage-material/manage-material.module').then(
            (m) => m.ManageMaterialModule
          ),
      },
      {
        path: 'manage-material-unit',
        loadChildren: () =>
          import('../manage-material-unit/manage-material-unit.module').then(
            (m) => m.ManageMaterialUnitModule
          ),
      },
      {
        path: 'manage-worktype',
        loadChildren: () =>
          import('../manage-worktype/manage-worktype.module').then(
            (m) => m.ManageWorktypeModule
          ),
      },
      {
        path: 'manage-store',
        loadChildren: () =>
          import('../manage-store/manage-store.module').then(
            (m) => m.ManageStoreModule
          ),
      },
      {
        path: 'manage-stock',
        loadChildren: () =>
          import('../manage-stock/manage-stock.module').then(
            (m) => m.ManageStockModule
          ),
      },
      {
        path: 'manage-project',
        loadChildren: () =>
          import('../manage-project/manage-project.module').then(
            (m) => m.ManageProjectModule
          ),
      },
      // {
      //   path: 'manage-function',
      //   loadChildren: () => import('../manage-function/manage-function.module').then(m => m.ManageFunctionModule)
      // },
      // {
      //   path: 'manage-role',
      //   loadChildren: () => import('../manage-role/manage-role.module').then(m => m.ManageRoleModule)
      // },
      // {
      //   path: 'manage-user',
      //   loadChildren: () => import('../manage-user/manage-user.module').then(m => m.ManageUserModule)
      // },
      {
        path: 'transport',
        loadChildren: () =>
          import('../transport/transport.module').then(
            (m) => m.TransportModule
          ),
      },
      {
        path: 'report',
        loadChildren: () =>
          import('../report/report.module').then((m) => m.ReportModule),
      },
      {
        path: 'stock-audit',
        loadChildren: () =>
          import('../stock-audit/stock-audit.module').then(
            (m) => m.StockAuditModule
          ),
      },
      {
        path: 'manage-master-configuration',
        loadChildren: () =>
          import(
            '../manage-master-configuration/manage-master-configuration.module'
          ).then((m) => m.ManageMasterConfigurationModule),
      },
      {
        path: 'manage-transport-driver',
        loadChildren: () =>
          import('../manage-company-driver/manage-company-driver.module').then(
            (m) => m.ManageCompanyDriverModule
          ),
      },
      {
        path: 'request-without-bom',
        loadChildren: () =>
          import('../mapping-dummy/mapping-dummy.module').then(
            (m) => m.MappingDummyModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MainRoutingModule {
  static components = [MainComponent];
}
