import { DropdownMaterialUnit } from './material-unit';
export class Material {
  materialId: number;
  materialCode: string;
  materialName: string;
  materialStock: MaterialStock | null;
  materialUnit: MaterialUnit | null;
  materialBOM: MaterialBOM | null;
  materialWeight?: number = 0;
  /**
   *
   */
  constructor(o: Material) {
    this.materialId = o.materialId;
    this.materialCode = o.materialCode;
    this.materialName = o.materialName;
    this.materialStock = o.materialStock;
    this.materialUnit = o.materialUnit;
    this.materialBOM = o.materialBOM;
    this.materialWeight = o.materialWeight;
  }
}

export class BOMMappingMaterial extends Material {
  bomWorkTypeMaterialId?: number;

  /**
   *
   */
  constructor(o: BOMMappingMaterial) {
    super(o);
    this.bomWorkTypeMaterialId = o.bomWorkTypeMaterialId;
  }
}

export class MaterialStock {
  currentStock: number;
  safetyStock: number;
  suggestStock: number;
  auditCurrentStock: number = 0;
  auditRemark: string = '';

  /**
   *
   */
  constructor(o: MaterialStock) {
    this.currentStock = o.currentStock;
    this.safetyStock = o.safetyStock;
    this.suggestStock = o.suggestStock;
    this.auditCurrentStock = o.auditCurrentStock;
    this.auditRemark = o.auditRemark;
  }
}

export class MaterialUnit {
  materialUnitId: number;
  materialUnitCode: string;
  materialUnitName: string;

  /**
   *
   */
  constructor(o: MaterialUnit) {
    this.materialUnitId = o.materialUnitId;
    this.materialUnitCode = o.materialUnitCode;
    this.materialUnitName = o.materialUnitName;
  }
}

export class MaterialBOM {
  totalBOM: number;
  totalRequest: number | null;
  availableBOM: number | null;

  /**
   *
   */
  constructor(o: MaterialBOM) {
    this.totalBOM = o.totalBOM ?? 0;
    this.totalRequest = o.totalRequest ?? 0;
    this.availableBOM = o.availableBOM ?? 0;
  }
}

export class Materiallist {
  materialId: number;
  materialCode: string;
  materialName: string;
  materialUnitId: number;
  materialUnitCode: string;
  materialUnitName: string;
  materialWeight: number;
  totalRows: number;
  active: boolean;
  createDate?: string;
  createBy?: string;
  updateDate?: string;
  updateBy?: string;
  materialUnit: DropdownMaterialUnit;
  errorMessage?: string;

  constructor(m: Materiallist) {
    this.materialId = m.materialId;
    this.materialCode = m.materialCode;
    this.materialName = m.materialName;
    this.materialUnitId = m.materialUnitId;
    this.materialUnitCode = m.materialUnitCode;
    this.materialUnitName = m.materialUnitName;
    this.materialWeight = m.materialWeight;
    this.totalRows = m.totalRows;
    this.active = m.active;
    this.createDate = m.createDate;
    this.createBy = m.createBy;
    this.updateDate = m.updateDate;
    this.updateBy = m.updateBy;
    this.materialUnit = m.materialUnit;
    this.errorMessage = m.errorMessage;
  }
}

export class MaterialModel {
  materialId: number;
  materialCode: string;
  materialName: string;
  materialUnitId: number;
  materialUnitCode: string;
  materialUnitName: string;
  materialWeight: number;
  totalRows: number;
  active: boolean;
  createDate?: string;
  createBy?: string;
  updateDate?: string;
  updateBy?: string;

  constructor(m: MaterialModel) {
    this.materialId = m.materialId;
    this.materialCode = m.materialCode;
    this.materialName = m.materialName;
    this.materialUnitId = m.materialUnitId;
    this.materialUnitCode = m.materialUnitCode;
    this.materialUnitName = m.materialUnitName;
    this.materialWeight = m.materialWeight;
    this.totalRows = m.totalRows;
    this.active = m.active;
    this.createDate = m.createDate;
    this.createBy = m.createBy;
    this.updateDate = m.updateDate;
    this.updateBy = m.updateBy;
  }
}

export class MaterialStockAudit {
  auditFlag: boolean = false;
  stockAuditId: number = 0;
  stockAuditStatusId: number = 0;
  stockAuditStatusName: string = '';
  materialId: number = 0;
  materialCode: string = '';
  materialName: string = '';
  materialUnitId: number = 0;
  materialUnitCode: string = '';
  materialUnitName: string = '';
  receiveMaterialInfo: MaterialReceive = new MaterialReceive();
  materialRequest: MaterialRequest = new MaterialRequest();
  standardStock: number = 0.0;
  safetyStock: number = 0.0;
  currentStock: number = 0.0;
  auditCurrentStock: number = 0.0;
  auditRemark: string = '';
  materialUnit?: MaterialUnit;
  materialStock?: MaterialStock;
  summaryTotalReceiveDiff: number = 0.0;
  summaryTotalRequestDiff: number = 0.0;
  totalCountLastAuditStock: number = 0.0;
  totalReceiveAfterAudit: number = 0.0;

  toInterface(o: MaterialStockAudit) {
    const object: IMaterialStockAudit = {
      materialCode: o.materialCode,
      materialName: o.materialName,
      materialUnitCode: o.materialUnitCode,
      materialUnitName: o.materialUnitName,
      standardStock: o.standardStock,
      summaryTotalReceive: o.receiveMaterialInfo?.totalReceive ?? 0.0,
      summaryTotalRequest: o.materialRequest?.totalRequest ?? 0.0,
      summaryTotalReceiveDiff: o.summaryTotalReceiveDiff ?? 0.0,
      summaryTotalRequestDiff: o.summaryTotalRequestDiff ?? 0.0,
      totalCountLastAuditStock: o.totalCountLastAuditStock ?? 0.0,
      totalReceiveAfterAudit: o.totalReceiveAfterAudit ?? 0.0,
      currentStock: o.currentStock,
      stockAuditStatusId: o.stockAuditStatusId,
      stockAuditStatusName: o.stockAuditStatusName,
      auditCurrentStock: o.auditCurrentStock,
      auditRemark: o.auditRemark,
    };

    return object;
  }
}

export interface IMaterialStockAudit {
  materialCode: string;
  materialName: string;
  materialUnitCode: string;
  materialUnitName: string;
  standardStock: number;
  summaryTotalReceive: number;
  summaryTotalRequest: number;
  summaryTotalReceiveDiff: number;
  summaryTotalRequestDiff: number;
  totalCountLastAuditStock: number;
  totalReceiveAfterAudit: number;
  currentStock: number;
  stockAuditStatusId: number;
  stockAuditStatusName: string;
  auditCurrentStock: number;
  auditRemark: string;
}

export class MaterialReceive {
  totalReceive: number = 0;
  receiveRemark: string = '';
}

export class MaterialRequest {
  totalRequest: number = 0;
}
