<!-- filter -->
<div style="margin-bottom: 20px">
  <mat-accordion>
    <mat-expansion-panel [expanded]="options.isExpand">
      <!-- hideToggle -->
      <mat-expansion-panel-header>
        <mat-panel-title>กรองข้อมูล</mat-panel-title>
        <mat-panel-description>{{ summaryFilteredText }}</mat-panel-description>
      </mat-expansion-panel-header>
      <div class="layout-three-column">
        <div *ngIf="options.search.visible">
          <mat-form-field appearance="standard">
            <mat-label>ค้นหา</mat-label>
            <input
              matInput
              type="text"
              placeholder="ตย. พิมพ์เพื่อค้นหา"
              [required]="options.search.required!"
              [(ngModel)]="searchText"
            />
          </mat-form-field>
        </div>
        <div *ngIf="options.dateRangePicker.visible">
          <mat-form-field appearance="standard">
            <mat-label>วันที่ทำรายการ</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matInput
                matStartDate
                placeholder="วันที่เริ่ม"
                disabled
                [(ngModel)]="startDate"
                #dateRangeStart
                (dateChange)="
                  dateRangeChange(dateRangeStart, dateRangeEnd, true)
                "
                [required]="options.dateRangePicker.required!"
              />
              <input
                matInput
                matEndDate
                placeholder="วันที่สิ้นสุด"
                disabled
                [(ngModel)]="endDate"
                #dateRangeEnd
                (dateChange)="
                  dateRangeChange(dateRangeStart, dateRangeEnd, false)
                "
                [required]="options.dateRangePicker.required!"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker
              #picker
              disabled="false"
            ></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div *ngIf="options.dropdownProject.visible">
          <mat-form-field appearance="standard">
            <mat-label>โครงการ</mat-label>
            <input
              type="text"
              matInput
              [formControl]="projectFC"
              [matAutocomplete]="auto"
              [required]="options.dropdownProject.required!"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option
                *ngFor="let option of filteredProjectOptions | async"
                [value]="option"
              >
                {{ option.projectCode + " - " + option.projectName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div *ngIf="options.dropdownStore.visible">
          <mat-form-field appearance="standard">
            <mat-label>Store</mat-label>
            <input
              type="text"
              matInput
              [formControl]="storeFC"
              [matAutocomplete]="auto"
              [required]="options.dropdownStore.required!"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayStoreFn"
            >
              <mat-option
                *ngFor="let option of filteredStoreOptions | async"
                [value]="option"
              >
                {{ option.storeCode + " - " + option.storeName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div *ngIf="options.dropdownMaterial.visible">
          <mat-form-field appearance="standard">
            <mat-label>Material</mat-label>
            <input
              type="text"
              matInput
              [formControl]="materialFC"
              [matAutocomplete]="auto"
              [required]="options.dropdownMaterial.required!"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayMaterialFn"
            >
              <mat-option
                *ngFor="let option of filteredMaterialOptions | async"
                [value]="option"
              >
                {{ option.materialCode + " - " + option.materialName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div *ngIf="options.dropdownTransportStatus.visible">
          <mat-form-field appearance="standard">
            <mat-label>สถานะการจัดส่ง</mat-label>
            <mat-select
              [(value)]="filteredTransportStatus"
              [required]="options.dropdownTransportStatus.required!"
            >
              <mat-option>ไม่ระบุ</mat-option>
              <mat-option
                *ngFor="let transportStatus of dropdownTransportStatus"
                [value]="transportStatus"
              >
                {{ transportStatus.transportStatusName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="options.dropdownWorkType.visible">
          <mat-form-field appearance="standard">
            <mat-label>งาน</mat-label>
            <mat-select
              [(value)]="filteredWorkType"
              [required]="options.dropdownWorkType.required!"
            >
              <mat-option>ไม่ระบุ</mat-option>
              <mat-option
                *ngFor="let workType of dropdownWorkType"
                [value]="workType"
              >
                {{ workType.workTypeCode + " - " + workType.workTypeName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="options.dropdownModelType.visible">
          <mat-form-field appearance="standard">
            <mat-label>Model Type</mat-label>
            <mat-select
              [(value)]="filteredModelType"
              [required]="options.dropdownModelType.required!"
            >
              <mat-option>ไม่ระบุ</mat-option>
              <mat-option
                *ngFor="let modelType of dropdownModelType"
                [value]="modelType"
              >
                {{ modelType.modelCode }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="options.documentCodeInput.visible">
          <mat-form-field appearance="standard">
            <mat-label>Document Code</mat-label>
            <input
              matInput
              type="text"
              placeholder="พิมพ์เพื่อกรองข้อมูลด้วย Document Code"
              [(ngModel)]="filteredDocumentCode"
              [required]="options.documentCodeInput.required!"
            />
          </mat-form-field>
        </div>

        <div *ngIf="options.unitCodeInput.visible">
          <mat-form-field appearance="standard">
            <mat-label>แปลง</mat-label>
            <input
              matInput
              type="text"
              placeholder="พิมพ์เพื่อค้นหาข้อมูลแปลง"
              [(ngModel)]="filteredUnitCode"
              [required]="options.unitCodeInput.required!"
            />
          </mat-form-field>
        </div>

        <div *ngIf="options.materialDocumentInput.visible">
          <mat-form-field appearance="standard">
            <mat-label>Material Document</mat-label>
            <input
              matInput
              type="text"
              placeholder="Material Document"
              [(ngModel)]="filteredMaterialDocument"
              [required]="options.materialDocumentInput.required!"
            />
          </mat-form-field>
        </div>

        <div *ngIf="options.checkBoxOverBOMFlag.visible">
          <p>
            <mat-checkbox color="primary" [(ngModel)]="filteredFlagOverBOM"
              >เบิกเกิน BOM</mat-checkbox
            >
          </p>
        </div>

        <div *ngIf="options.showZoneOnly.visible">
          <p>
            <mat-checkbox color="primary" [(ngModel)]="this.showZoneOnly"
              >ดูข้อมูลส่วนกลางเท่านั้น</mat-checkbox
            >
          </p>
        </div>
      </div>
      <div class="layout-full-right">
        <button mat-stroked-button color="warn" (click)="clearFilters()">
          ล้าง
        </button>
        <span class="tiny-space"></span>
        <button mat-stroked-button color="primary" (click)="applyFilters()">
          ปรับใช้
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<!-- ----- end filter ----- -->
