import { ConfirmDialogComponent } from './../../shared/confirm-dialog/confirm-dialog.component';
import { ManageMaterialService } from './../../core/services/manage-material/manage-material.service';
import { DropdownService } from './../../core/providers/dropdown.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Project } from 'src/app/core/models/project';
import { WorkType } from 'src/app/core/models/workType';
import { ModelType } from 'src/app/core/models/modelType';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ApiRequest, toQueryParam } from 'src/app/core/models/api-request';
import { BOMMapping } from 'src/app/core/models/bom';
import {
  Materiallist,
  MaterialModel,
  MaterialUnit,
} from 'src/app/core/models/material';
import { MatTable } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { MatPaginator } from '@angular/material/paginator';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { ManageMaterialDialogComponent } from './manage-material-dialog/manage-material-dialog/manage-material-dialog.component';
import { ImportManageMaterialDialogComponent } from './Import-manage-material-dialog/Import-manage-material-dialog/Import-manage-material-dialog.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manage-material',
  templateUrl: './manage-material.component.html',
  styleUrls: ['./manage-material.component.scss'],
})
export class ManageMaterialComponent implements OnInit {
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  dropdownProject = Array<Project>();
  dropdownWorkType = Array<MaterialUnit>();
  dropdownModelType = Array<ModelType>();
  filteredProject: Project | null = null;
  filteredModelType: ModelType | null = null;
  filteredWorkType: WorkType | null = null;
  fileUploadForm!: FormGroup;

  @ViewChild(MatTable) table!: MatTable<Materiallist>;
  @ViewChild('paginator') paginator!: MatPaginator;

  fileInputLabel: string = '';
  displayedColumns: string[] = [
    'no',
    'materialCode',
    'materialName',
    'materialWeight',
    'materialUnitName',
    'updateDate',
    'updateBy',
    'actions',
  ];
  dataSource: Materiallist[] = [];
  dataSourcelist: Materiallist[] = [];

  totalRows = 1;
  queryParam = new ApiRequest({
    search: '',
    startDate: '',
    endDate: '',
    sortBy: '',
    orderBy: '',
    start: 1,
    limit: 10,
  });

  queryParamAll = new ApiRequest({
    search: '',
    startDate: '',
    endDate: '',
    sortBy: '',
    orderBy: '',
    start: 1,
    limit: 10000000,
  });

  constructor(
    private dropdown: DropdownService,
    private dialog: MatDialog,
    private ManageMaterialService: ManageMaterialService
  ) {}

  async ngOnInit(): Promise<void> {
    this.dropdownWorkType = await this.dropdown.getDropdownMaterialUnit();
    await this.fetchTable(this.queryParam);
  }

  resetPaginator() {
    this.queryParam.start = 1;
    this.queryParam.limit = 10;
    this.paginator.firstPage();
  }

  async fetchTable(queryParam: ApiRequest) {
    this.queryParam = queryParam;
    Swal.showLoading();
    this.dataSource = await this.ManageMaterialService.getMaterialList(
      this.queryParam
    );
    Swal.close();
    this.totalRows =
      this.dataSource.length > 0 ? this.dataSource[0].totalRows! : 0;
    this.table!.renderRows();
  }

  onCreate(element?: Materiallist) {
    if (element && !element.active) return;

    const dialogRef = this.dialog.open(ManageMaterialDialogComponent, {
      width: '900px',
      disableClose: true,
      data: {
        dropdownProject: this.dropdownProject,
        dropdownModelType: this.dropdownModelType,
        dropdownWorkType: this.dropdownWorkType,
        editValue: element ?? null,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.fetchTable(this.queryParam);
    });
  }

  async ImportExcel(element?: Materiallist) {
    if (element && !element.active) return;

    this.dataSourcelist = await this.ManageMaterialService.getMaterialList(
      this.queryParamAll
    );

    const dialogRef = this.dialog.open(ImportManageMaterialDialogComponent, {
      width: '900px',
      disableClose: true,
      data: {
        dropdownProject: this.dropdownProject,
        dropdownModelType: this.dropdownModelType,
        dropdownWorkType: this.dropdownWorkType,
        editValue: element ?? null,
        Materiallist: this.dataSourcelist,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      await this.fetchTable(this.queryParamAll);
    });
  }

  async onUpdateStatus(element: Materiallist) {
    if (element.active) {
      await this.onDelete(element);
    } else {
      await this.onActive(element);
    }
  }
  async onActive(item: Materiallist) {
    item.active = true;
    let updateBOMMapping = new MaterialModel({
      materialId: item.materialId,
      materialName: item.materialName,
      materialUnitId: item.materialUnit.materialUnitId,
      materialCode: item.materialCode,
      materialUnitCode: '',
      materialUnitName: '',
      materialWeight: 0,
      totalRows: 0,
      active: true,
      createDate: '',
      createBy: '',
      updateDate: '',
      updateBy: '',
    });

    await this.ManageMaterialService.updateMaterial(
      item.materialId,
      updateBOMMapping
    );
    await this.fetchTable(this.queryParam);
  }

  async onDelete(item: Materiallist) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      disableClose: true,
      data: {
        title: 'ยืนยันที่จะลบข้อมูลใช่หรือไม่ ?',
        content: `Material '${item.materialName}' จะถูกปรับสถานะเป็น 'ปิดการใช้งาน'`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: boolean) => {
      if (result) {
        item.active = false;
        let updateBOMMapping = new MaterialModel({
          materialId: item.materialId,
          materialName: item.materialName,
          materialUnitId: item.materialUnit.materialUnitId,
          materialCode: item.materialCode,
          materialUnitCode: '',
          materialUnitName: '',
          materialWeight: 0,
          totalRows: 0,
          active: item.active,
          createDate: '',
          createBy: '',
          updateDate: '',
          updateBy: '',
        });

        Swal.showLoading();
        await this.ManageMaterialService.updateMaterial(
          item.materialId,
          updateBOMMapping
        );
        Swal.close();
      }
      await this.fetchTable(this.queryParam);
    });
  }
}
