<app-toolbar
  (onToggleDrawer)="this.isSidenavOpen = !this.isSidenavOpen"
  class="app-toolbar"
></app-toolbar>

<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer #drawer class="sidenav" mode="side" [opened]="isSidenavOpen">
    <mat-nav-list>
      <div class="mat-nav-group">
        <a mat-list-item disabled>
          <mat-icon matListIcon>article</mat-icon> ทั่วไป
        </a>
        <a
          mat-list-item
          routerLink="/transport"
          [routerLinkActive]="['routerLinkActive']"
          >ใบนำส่งวัสดุ</a
        >
        <a
          mat-list-item
          routerLink="/stock-audit"
          [routerLinkActive]="['routerLinkActive']"
          >ตรวจนับวัสดุคงคลัง</a
        >
        <a
          mat-list-item
          routerLink="/request-without-bom"
          [routerLinkActive]="['routerLinkActive']"
          >รายการเบิกนอก BOM</a
        >
      </div>

      <div class="mat-nav-group">
        <a mat-list-item disabled>
          <mat-icon matListIcon>settings</mat-icon> ตั้งค่าระบบ
        </a>
        <a
          mat-list-item
          routerLink="/manage-master-configuration"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการ Master Configuration</a
        >
        <a
          mat-list-item
          routerLink="/manage-project"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการโครงการ/แปลน/Type</a
        >
        <a
          mat-list-item
          routerLink="/manage-store"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการ Store กลาง/Store ประจำไซต์</a
        >
        <a
          mat-list-item
          routerLink="/manage-stock"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการ Stock</a
        >
        <a
          mat-list-item
          routerLink="/manage-material"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการวัสดุ</a
        >
        <a
          mat-list-item
          routerLink="/manage-material-unit"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการหน่วยวัสดุ</a
        >
        <a
          mat-list-item
          routerLink="/manage-worktype"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการหมวดงาน</a
        >
        <a
          mat-list-item
          routerLink="/bom-mapping"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการ BOM</a
        >
        <a
          mat-list-item
          routerLink="/manage-transport-driver"
          [routerLinkActive]="['routerLinkActive']"
          >จัดการข้อมูลพนักงานขับรถ</a
        >
        <!-- <a mat-list-item routerLink="/manage-function">จัดการข้อมูล Function</a>
        <a mat-list-item routerLink="/manage-role">จัดการข้อมูลสิทธิการใช้งาน</a>
        <a mat-list-item routerLink="/manage-user">จัดการข้อมูลผู้ใช้งาน</a> -->
      </div>

      <div class="mat-nav-group">
        <a mat-list-item disabled>
          <mat-icon matListIcon>assessment</mat-icon> รายงานและสถิติ
        </a>
        <a
          mat-list-item
          routerLink="/report/request-material"
          [routerLinkActive]="['routerLinkActive']"
          >สรุปการเบิกวัสดุรายแปลง</a
        >
        <a
          mat-list-item
          routerLink="/report/request-material-detail"
          [routerLinkActive]="['routerLinkActive']"
          >การเบิกวัสดุรายแปลง (ข้อมูลตาม Transaction)</a
        >
        <a
          mat-list-item
          routerLink="/report/request-status-material"
          [routerLinkActive]="['routerLinkActive']"
          >ไฟล์ Upload เข้าระบบ SAP</a
        >
        <a
          mat-list-item
          routerLink="/report/transfer-material"
          [routerLinkActive]="['routerLinkActive']"
          >สรุปการนำส่งและรับวัสดุเข้าโครงการ</a
        >
        <a
          mat-list-item
          routerLink="/report/stock-audit"
          [routerLinkActive]="['routerLinkActive']"
          >สรุปการตรวจนับวัสดุคงคลัง</a
        >
        <a
          mat-list-item
          routerLink="/report/request-status-unit"
          [routerLinkActive]="['routerLinkActive']"
          >สรุปสถานะใบเบิกวัสดุตามแปลง</a
        >
      </div>
    </mat-nav-list>
  </mat-drawer>
  <div class="main-container">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
